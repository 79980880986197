import React from 'react'
import classes from './UserItem.module.sass'
import {
  Action,
  Subject,
  UserInterface,
} from '../../../../common/interfaces/interfaces'
import { Status } from '../../../../common/components/status/Status'
import { ListButton } from '../../../../common/components/listButton/ListButton'
import { useAbility } from '../../../../common/hooks/useAbility'
import { useCurrentUser } from '../../../../common/hooks/useCurrentUser'

type Props = {
  user: UserInterface
  setModalActive: React.Dispatch<React.SetStateAction<boolean>>
  startEdit: (item: UserInterface) => void
  getRoleName: (roleId: string) => string
}

export const UserItem = ({ user, startEdit, getRoleName }: Props) => {
  const { checkAbility } = useAbility()

  const { currentUser } = useCurrentUser()

  return (
    <div className={classes.Container}>
      <div className={classes.UserInfoWrapper}>
        <div className={classes.LoginAndStatus}>
          <div className={classes.Login}>{user.login}</div>
        </div>

        <div className={classes.Fio}>{user.fio}</div>
        {!currentUser.super && (
          <div className={classes.Bold20}>
            <span className={classes.Role}>{`Роль: `}</span>
            {getRoleName(user.roleId)}
          </div>
        )}
      </div>

      <div className={classes.HorizontalLine} />

      <div className={classes.StatusAndButtonsWrapper}>
        <div className={classes.Active}>
          <Status
            title={{ positive: 'Активен', negative: 'Не активен' }}
            active_props={user.active}
          />
        </div>
        <div className={classes.VerticalLine} />
        <ListButton
          disabled={!checkAbility(Action.Update, Subject.Users)}
          onClick={() => {
            startEdit(user)
          }}
          title={'Редактировать'}
          type={'edit'}
        />
      </div>
    </div>
  )
}
