import { useCallback, useEffect, useState } from 'react'
import {
  PartnerInterface,
  ValidationResultInterface,
  ValidationRulesInterface,
} from '../../../../../common/interfaces/interfaces'
import { getApi } from '../../../../../api/api'
import { useLogout } from '../../../../../common/hooks/useLogout'
import { useInput } from '../../../../../common/hooks/useInput'
import { partnerValidations } from '../../../../../constants'
import { useValidation } from '../../../../../common/hooks/useValidation'

const validations: ValidationRulesInterface[] = partnerValidations

export const useForm = (
  initialValue: PartnerInterface,
  getList: (reUsed?: boolean) => void,
) => {
  const [inProgress, setInProgress] = useState<boolean>(false)
  const [data, setData] = useState<PartnerInterface>(initialValue)
  const [api] = useState(() => getApi())
  const { logOut } = useLogout()
  const [validationResult, setValidationResult] = useState<
    ValidationResultInterface[] | []
  >([])
  const [disabled, setDisabled] = useState<boolean>(true)

  const { validate, checkAndAddValidation, setDirty } = useValidation(
    setValidationResult,
    validationResult,
    validations,
  )

  const { inputHandler } = useInput(setData)

  useEffect(() => {
    for (var item in data) {
      if (validations.map((el) => el.fieldName).includes(item)) {
        checkAndAddValidation(item)
      }
    }
  }, [])

  useEffect(() => {
    for (var item in data) {
      if (validations.map((el) => el.fieldName).includes(item)) {
        validate(item, data[item])
      }
    }
  }, [data])

  useEffect(() => {
    setDisabled(validationResult.filter((el) => el.notValid).length > 0)
  }, [validationResult])

  const createOrUpdateAction = useCallback(async () => {
    try {
      if (data.id !== '') {
        await api.updatePartner(data)
      } else {
        const { id, ...rest } = data
        await api.createPartner({ ...rest })
      }
      await getList(true)
    } catch (error: any) {
      if (error.response.status === 401) logOut().then()
    }
  }, [api, data, getList, logOut])

  const deleteAction = useCallback(async () => {
    try {
      initialValue.id && (await api.deletePartner({ id: initialValue.id }))
      await getList(true)
    } catch (error: any) {
      if (error.response.status === 401) logOut().then()
    }
  }, [api, getList, initialValue.id, logOut])

  const getValidationResult = (fieldName: string) => {
    return validationResult.find((el) => el.name === fieldName)
  }

  return {
    inProgress,
    setInProgress,
    inputHandler,
    data,
    createOrUpdateAction,
    deleteAction,
    getValidationResult,
    disabled,
    setDirty,
  }
}
