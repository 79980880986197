import React from 'react'
import classes from './DeviceItem.module.sass'
import { DeviceItemInterface } from '../../../../common/interfaces/interfaces'
import { ListButton } from '../../../../common/components/listButton/ListButton'
import { Shorten } from '../../../../common/components/shorten/Shorten'
import online from '../../../../assets/images/icons/online.svg'
import offline from '../../../../assets/images/icons/offline.svg'

type Props = {
  el: DeviceItemInterface
  startEdit: (item: DeviceItemInterface) => void
  active: boolean
}

export const DeviceItem = ({ el, startEdit, active }: Props) => {
  return (
    <div className={classes.Container}>
      <div className={classes.InItemWrapper}>
        <div className={classes.ContentWrapper}>
          <div className={classes.Id}>
            <Shorten id={el.id ? el.id : ''} />
          </div>
          <div className={classes.Name}>{el.name}</div>
          <div className={classes.DeviceId}>
            <span className={classes.DeviceIdTitle}>Id устройства: </span>
            <Shorten id={el.deviceId} />
          </div>

          <div className={classes.ActiveMobile}></div>
        </div>
        <div className={classes.ButtonAndActiveContainer}>
          <div className={classes.Active}>
            <img
              alt="Иконка доступности в сети/не в сети"
              src={active ? online : offline}
            />
          </div>
          <div className={classes.VerticalLine} />
          <div className={classes.ButtonContainer}>
            <ListButton
              title={'Редактировать'}
              type={'edit'}
              onClick={() => startEdit(el)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
