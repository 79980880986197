import {  useLocation, useNavigate } from 'react-router-dom';

export const useBar = () => {
  const navigate = useNavigate()
  const location = useLocation()



  return { navigate, location}
};
