import { useEffect, useState } from 'react'

export const useProgressCircle = (maxTime: number) => {
  const [intervalId] = useState<{ id: null | ReturnType<typeof setInterval> }>({
    id: null,
  })
  const [currentTime, setCurrentTime] = useState<number>(maxTime)
  const [currentSegment, setCurrentSegment] = useState<number>(0)

  useEffect(() => {
    intervalId.id = setInterval(() => {
      setCurrentTime((prev) => prev - 1)
    }, 500)
  }, [])

  useEffect(() => {
    setCurrentSegment((1 - currentTime / maxTime) * 100)
    if (currentTime === 0 && intervalId.id !== null)
      clearInterval(intervalId.id)
  }, [currentTime])

  return { currentTime, currentSegment }
}
