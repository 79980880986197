import React from 'react'
import classes from './Input.module.sass'
import {
  ValidationResultInterface,
} from '../../interfaces/interfaces'

type Props = {
  type: string
  value: string | number 
  placeHolder: string
  onChangeHandler: (
    value: string | number | boolean | (string | undefined)[] ,
    fieldName: string,
    type: string,
  ) => void
  fieldName: string
  itemValidationResult?: ValidationResultInterface
  onBlur?: (fieldName: string) => void
}


export const Input = ({
  type,
  value,
  placeHolder,
  onChangeHandler,
  fieldName,
  itemValidationResult,
  onBlur,
}: Props) => {

  

  return (
    <div className={classes.InputContainer}>
      <label className={classes.Label}>{placeHolder}</label>
      <input
        type={type}
        className={
          itemValidationResult?.isEmptyError
            ? classes.ContainerRequired
            : classes.Container
        }
        value={value}
        onChange={(e) => {
          onChangeHandler(e.target.value, fieldName, type)
        }}
        onBlur={() => onBlur && onBlur(fieldName)}
      />
      <div className={classes.ValidationErrorField}>
        {(itemValidationResult?.isDirty && itemValidationResult?.notValid) && itemValidationResult?.errorMessage}
      </div>
    </div>
  )
}
