import React from "react";
import classes from "./PageHeader.module.sass";
import { usePageHeader } from "./hooks/usePageHeader";

type Props = {
  length?: number;
  part_1_props?: string;
  part_2_props?: string;
  title_props?: string;
  icon_props?: string;
  mobile_title?: boolean
};

export const PageHeader = ({
  length,
  part_1_props,
  part_2_props,
  title_props,
  icon_props,
}: Props) => {
  const { part1, part2, icon, title } = usePageHeader();

  return (
    <div className={classes.Container}>
      <div className={classes.HeaderTextWrapper}>
        {part1 || part_1_props ? (
          <span className={classes.Part1}>
            {part_1_props ? part_1_props : part1}
          </span>
        ) : (
          <></>
        )}
        <span className={classes.Part2}>
          {part_2_props ? part_2_props : part2}
        </span>
      </div>
      {length || title_props ? (
        <div className={classes.TitleBlock}>
          <img src={icon_props ? icon_props : icon} alt="Иконка заголовка" />
          {length}{" "}
          <span className={classes.Title}>
            {title_props ? title_props : title}
          </span>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
