import React from 'react'
import classes from './Filter.module.sass'
import { Button } from '../button/Button'
import { useFilter } from './hooks/useFilter'
import filterArrow from '../../../assets/images/icons/filterArrow.svg'
import { BARRIER_LOGS_ROUTE } from '../../../constants'

type Props = {
  gte: string
  lte: string
  setFrom: (value: string) => void
  setTo: (value: string) => void
  getReport: () => void
  disabled: boolean
  dateMessage: string
}

export const Filter = ({
  gte,
  lte,
  setFrom,
  setTo,
  getReport,
  disabled,
  dateMessage,
}: Props) => {
  const { location } = useFilter()

  return (
    <div className={classes.OutlineContainer}>
      <div className={classes.Container}>
        <div className={classes.Title}>Фильтр</div>
        <div className={classes.InputsContainer}>
          {/* {location.pathname === BARRIER_LOGS_ROUTE && (
            <input
              className={classes.BarrierInput}
              type="text"
              placeholder="Шлагбаум"
            />
          )} */}

          <div className={classes.FieldsWithError}>
            <div className={classes.FieldsContainer}>
              <div className={classes.InputContainer}>
                <div className={classes.Anchor}>
                  {!gte && <div className={classes.InputStub}>Начиная с</div>}
                </div>
                <div className={classes.ToCenterInput}>
                  <input
                    onKeyDown={(e) => e.preventDefault()}
                    className={classes.Input}
                    type="date"
                    value={gte && new Date(gte).toISOString().split('T')[0]}
                    onChange={(e) => setFrom(e.target.value)}
                  />
                </div>
              </div>
              <img className={classes.Arrow} src={filterArrow} alt="C по" />
              <div className={classes.InputContainer}>
                <div className={classes.Anchor}>
                  {!lte && <div className={classes.InputStub}>Заканчивая</div>}
                </div>
                <div className={classes.ToCenterInput}>
                  <input
                    onKeyDown={(e) => e.preventDefault()}
                    className={classes.Input}
                    type="date"
                    value={lte && new Date(lte).toISOString().split('T')[0]}
                    onChange={(e) => setTo(e.target.value)}
                  />
                </div>
              </div>
            </div>
            {disabled && (
              <div className={classes.ValidationErrorField}>{dateMessage}</div>
            )}
          </div>

          {location.pathname !== BARRIER_LOGS_ROUTE && (
            <>
              {/* <FilterWithGroupSelection
                initialValue={partnersInitialValue}
                allText="Все партнеры"
              /> */}
              {/* <FilterWithGroupSelection
                initialValue={parkingListInitialValue}
                allText="Все парковки"
              /> */}
            </>
          )}
        </div>
        <Button disabled={disabled} text="Применить" onClick={getReport} />
      </div>
    </div>
  )
}
