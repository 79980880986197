import { useState } from "react"

export const useParkingItem = () => {
const [expanded, setExpanded] = useState<boolean>(true)

const toggleLevel = ()=>{
    setExpanded(prev=>!prev)
}

  return {expanded, toggleLevel}
}
