import { useDispatch } from 'react-redux'
import { NotificationInterface } from '../interfaces/interfaces'
import { addNotification } from '../../store/slices/notificationSlice'

export const useNotification = () => {
  const dispatch = useDispatch()

  const addNotificationAction = (el: NotificationInterface) => {
    dispatch(addNotification(el))
  }

  return { addNotificationAction }
}
