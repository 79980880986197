import { useEffect, useState } from 'react'
import {
  UserInterface,
  UserInterfaceWithAbility,
} from '../interfaces/interfaces'
import { useLocation } from 'react-router-dom'

export const initialValuePattern = JSON.stringify({
  id: '',
  login: '',
  fio: '',
  active: false,
  roleId: '1',
  email: '',
  super: false,
  partnerId: '',
  parkingList: [],
  abilities: [],
})

export const useCurrentUser = () => {
  const [currentUser, setCurrentUser] = useState<UserInterfaceWithAbility>(
    JSON.parse(initialValuePattern),
  )
  const location = useLocation()

  useEffect(() => {
    const user: string | null = localStorage.getItem('currentUser')
    setCurrentUser(
      user !== null ? JSON.parse(user) : JSON.parse(initialValuePattern),
    )
  }, [location])

  return { currentUser }
}
