import React from 'react'
import classes from './AppWrapper.module.sass'
import { LeftSideMenu } from '../../common/components/leftSideMenu/LeftSideMenu'
import { Bar } from '../../common/components/bar/Bar'
import { useAppWrapper } from './hooks/useAppWrapper'
import { NotificationList } from '../../common/notificationList/NotificationList'
import { useSocket } from '../../common/hooks/useSocket'

type Props = {
  children: JSX.Element | JSX.Element[]
}

export const AppWrapper = ({ children }: Props) => {
  const { menuActive, toggleMenu } = useAppWrapper()
  useSocket()

  return (
    <div className={classes.Container}>
      <Bar menuActive={menuActive} toggleMenu={toggleMenu} />
      <NotificationList />
      <div className={!menuActive ? classes.ContentContainer : classes.ContentContainerOverflowHidden} >
        {' '}
        <LeftSideMenu menuActive={menuActive} toggleMenu={toggleMenu} />
        {children}
      </div>
    </div>
  )
}
