import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  PartnerInterface,
  PartnerList,
} from '../../common/interfaces/interfaces'
import { RootState } from '../store'

const initialState: PartnerList = {
  data: [],
}

export const partnerListSlice = createSlice({
  name: 'partner-list',
  initialState,
  reducers: {
    setPartnerList: (state, action: PayloadAction<PartnerInterface[]>) => {
      state.data = action.payload
    },
  },
})

export const { setPartnerList } = partnerListSlice.actions
export const selectPartnerList = (state: RootState) => state.partnerList.data

export default partnerListSlice.reducer
