import React from 'react'
import classes from './Partners.module.sass'
import { PageHeader } from '../../common/components/pageHeader/PageHeader'
import { Button } from '../../common/components/button/Button'
import { Modal } from '../../common/components/modal/Modal'
import { Form } from './components/form/Form'
import { usePartners } from './hooks/usePartners'
import { PartnerItem } from './components/partnerItem/PartnerItem'
import partnerIconSmall from '../../assets/images/icons/partnerIconSmall.svg'
import PlusCircle from '../../assets/images/icons/PlusCircle.svg'
import { CounterBookmark } from '../../common/components/counterBookmark/CounterBookmark'
import { ListLoader } from '../../common/components/listLoader/ListLoader'
import { Pagination } from '../../common/components/pagination/Pagination'

export const Partners = () => {
  const {
    list,
    modalActive,
    setModalActive,
    edit,
    initialValue,
    startEdit,
    startCreate,
    getList,
    loading,
    message,
    active,
    activeCount,
    toggleActive,
    totalCount,
    limit,
    offset,
    setOffset,
  } = usePartners()

  return (
    <div className={classes.Container}>
      <div className={classes.HeaderAndButtonWrapper}>
        <PageHeader length={totalCount} icon_props={partnerIconSmall} />
        <div className={classes.BookmarksAndAdd}>
          <CounterBookmark
            active={active}
            allLength={totalCount}
            activeLength={activeCount}
            toggleActive={toggleActive}
          />
          <Button icon={PlusCircle} text="Добавить" onClick={startCreate} />
        </div>
      </div>

      <div className={classes.ListContainer}>
        {list.length > 0 ? (
          list.map((el) => (
            <PartnerItem key={el.id} el={el} startEdit={startEdit} />
          ))
        ) : loading ? (
          <ListLoader />
        ) : !loading ? (
          <div className={classes.LoaderContainer}>
            <span className={classes.Message}>{message}</span>
          </div>
        ) : (
          <ListLoader />
        )}
      </div>

      <Pagination
        totalCount={active ? activeCount : totalCount}
        limit={limit}
        offset={offset}
        setOffset={setOffset}
      />

      <Modal modalActive={modalActive} setModalActive={setModalActive}>
        <Form
          setModalActive={setModalActive}
          initialValue={initialValue}
          edit={edit}
          getList={getList}
        />
      </Modal>
    </div>
  )
}
