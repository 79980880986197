import classes from './Status.module.sass'
import green_point from '../../../assets/images/icons/green_point.svg'
import red_point from '../../../assets/images/icons/red_point.svg'
import caret_down from '../../../assets/images/icons/caret_down.svg'
import { useStatus } from './hooks/useStatus'
import caret_down_red from '../../../assets/images/icons/caret_down_red.svg'
import { SetStateAction } from 'react'

type Props = {
  status?: boolean | undefined
  title: { positive: string; negative: string }
  onClick?: (newStatus?: boolean) => void
  pointer?: boolean
  active_props?: boolean
  toggle?: boolean
  fixedWith?: string
  mainClick?: boolean
}

export const Status = ({
  title,
  onClick,
  pointer,
  active_props,
  toggle,
  fixedWith,
  mainClick,
}: Props) => {
  const { active, statusHandler, expanded, setExpanded, expandHandler } =
    useStatus(active_props)

  return (
    <div>
      <div className={pointer ? classes.ContainerPointer : ''}>
        <div
          className={active ? classes.Container : classes.ContainerNegative}
          onClick={() => {
            mainClick && onClick && onClick()
            toggle && expandHandler()
          }}
          style={{ width: fixedWith ? fixedWith : 'max-content' }}
        >
          <img src={active ? green_point : red_point} alt="Цвет статуса" />
          <span className={classes.StatusTitle}>
            {active ? title.positive : title.negative}
          </span>
          {toggle && (
            <img src={active ? caret_down : caret_down_red} alt="Развернуть" />
          )}
        </div>
      </div>
      {expanded && (
        <div className={classes.ContainerExpandedAnchor}>
          <div className={classes.ContainerExpanded}>
            <div
              style={{ width: fixedWith ? fixedWith : 'max-content' }}
              className={
                !active ? classes.Container : classes.ContainerNegative
              }
              onClick={() => {
                onClick ? onClick(!active_props) : statusHandler()
                statusHandler()
              }}
            >
              <img src={!active ? green_point : red_point} alt="Цвет статуса" />
              <span className={classes.StatusTitle}>
                {!active ? title.positive : title.negative}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
