import React from 'react'
import classes from './WhiteList.module.sass'
import { PageHeader } from '../../../common/components/pageHeader/PageHeader'
import ShieldCheck from '../../../assets/images/icons/ShieldCheck.svg'

import { Button } from '../../../common/components/button/Button'
import { useWhiteList } from './hooks/useWhiteList'
import { WhiteListItem } from './components/whiteListItem/WhiteListItem'
import { Modal } from '../../../common/components/modal/Modal'
import { Form } from './components/form/Form'
import { Confirmation } from './components/confirmation/Confirmation'
import Trash from '../../../assets/images/icons/Trash.svg'
import caret_down from '../../../assets/images/icons/caret_down.svg'
import plus from '../../../assets/images/icons/PlusCircle.svg'
import { CounterBookmark } from '../../../common/components/counterBookmark/CounterBookmark'
import { ListLoader } from '../../../common/components/listLoader/ListLoader'
import { Pagination } from '../../../common/components/pagination/Pagination'
import { Action, Subject } from '../../../common/interfaces/interfaces'
export const WhiteList = () => {
  const {
    list,
    modalActive,
    setModalActive,
    startEdit,
    startCreate,
    edit,
    groupSelection,
    toGroup,
    resetGroup,
    modalActive1,
    setModalActive1,
    selectedExpanded,
    toggleSelectedExpanded,
    initialValue,
    getList,
    deleteGroup,
    loading,
    message,
    totalCount,
    limit,
    offset,
    setOffset,
    active,
    activeCount,
    toggleActive,
    checkAbility,
  } = useWhiteList()

  return (
    <div className={classes.Container}>
      <div className={classes.HeaderAndButtonWrapper}>
        <PageHeader length={totalCount} icon_props={ShieldCheck} />
        <div className={classes.BookmarksAndAdd}>
          <CounterBookmark
            active={active}
            allLength={totalCount}
            activeLength={activeCount}
            toggleActive={toggleActive}
          />
          <Button
            text="Добавить"
            onClick={startCreate}
            icon={plus}
            disabled={!checkAbility(Action.Create, Subject.WhiteList)}
          />
        </div>
        <div className={classes.GroupAction}>
          <div
            onClick={() =>
              groupSelection.length > 0 && toggleSelectedExpanded()
            }
            className={groupSelection.length > 0 ? classes.GroupPointer : ''}
          >
            Выбрано:{' '}
            <span className={classes.SelectedCount}>
              {' '}
              {groupSelection.length}
            </span>
            <img src={caret_down} alt="Развернуть кнопку сброса" />
          </div>
          <div className={classes.ResetContainerAnchor}>
            {selectedExpanded && (
              <div className={classes.ResetContainer}>
                {groupSelection.length > 0 && (
                  <Button text="Очистить" onClick={resetGroup} filledRed />
                )}
              </div>
            )}
          </div>
          {groupSelection.length > 0 && (
            <Button
              disabled={!checkAbility(Action.Delete, Subject.WhiteList)}
              icon={Trash}
              text="Удалить"
              onClick={() => setModalActive1(true)}
              red
            />
          )}
        </div>
      </div>
      <div className={classes.ListContainer}>
        {list.length > 0 ? (
          list.map((listItem) => (
            <WhiteListItem
              key={listItem.id}
              listItem={listItem}
              startEdit={() => startEdit(listItem)}
              toGroup={toGroup}
              inGroup={groupSelection.map((el) => el.id).includes(listItem.id)}
              getList={getList}
            />
          ))
        ) : !loading ? (
          <div className={classes.LoaderContainer}>
            <span className={classes.Message}>{message}</span>
          </div>
        ) : (
          <ListLoader />
        )}
      </div>

      <Pagination
        totalCount={!active ? totalCount : activeCount}
        limit={limit}
        offset={offset}
        setOffset={setOffset}
      />

      <Modal modalActive={modalActive} setModalActive={setModalActive}>
        <Form
          initialValue={initialValue}
          setModalActive={setModalActive}
          edit={edit}
          getList={getList}
        />
      </Modal>
      <Modal modalActive={modalActive1} setModalActive={setModalActive1}>
        <Confirmation
          setModalActive={setModalActive1}
          deleteGroup={deleteGroup}
        />
      </Modal>
    </div>
  )
}
