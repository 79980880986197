import React from 'react'
import classes from './NotificationItem.module.sass'
import {
  NotificationInterface,
  NotificationTypes,
} from './../../../interfaces/interfaces'
import { useNotificationItem } from './hooks/useNotificationItem'

type Props = {
  el: NotificationInterface
}

export const NotificationItem = ({ el }: Props) => {
  const { removeNotificationAction, notificationWidth, exit } =
    useNotificationItem(el)

  return (
    <div
      className={exit ? classes.CardContainerExit : classes.CardContainer}
      onClick={removeNotificationAction}
    >
      <div className={classes.Container}>
        <div className={classes.Message}>{el.text}</div>
        <div
          className={
            el.type === NotificationTypes.ERROR
              ? classes.StatusBar
              : classes.StatusBarPositive
          }
          style={{ width: notificationWidth - 10 }}
        />
        <div className={classes.ClickToHide}>Кликните, чтобы скрыть</div>
      </div>
    </div>
  )
}
