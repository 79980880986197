import React from 'react'
import classes from './Form.module.sass'
import { useForm } from './hooks/useForm'
import are_you_sure from '../../../../../assets/images/icons/are_you_sure.svg'
import { Input } from '../../../../../common/components/input/Input'
import {
  Action,
  ListItemInterface,
  Subject,
} from '../../../../../common/interfaces/interfaces'
import { Button } from '../../../../../common/components/button/Button'
import BlackListEdit from '../../../../../assets/images/icons/BlackListCircleEdit.svg'
import BlackListAdd from '../../../../../assets/images/icons/BlackListCircle.svg'
import { CheckBox } from '../../../../../common/components/checkBox/CheckBox'
import { Select } from '../../../../../common/components/select/Select'
import { FilterWithGroupSelection } from '../../../../../common/components/filterWithGroupSelection/FilterWithGroupSelection'

type Props = {
  edit?: boolean
  setModalActive: React.Dispatch<React.SetStateAction<boolean>>
  initialValue: ListItemInterface
  getList: () => void
}

export const Form = ({
  edit,
  setModalActive,
  initialValue,
  getList,
}: Props) => {
  const {
    inProgress,
    setInProgress,
    inputHandler,
    data,
    createOrUpdateAction,
    deleteAction,
    getValidationResult,
    disabled,
    setDirty,
    getPartnerList,
    currentUser,
    getParkingList,
    checkAbility,
  } = useForm(initialValue, getList)

  if (!inProgress)
    return (
      <div className={classes.Container} onClick={(e) => e.stopPropagation()}>
        <div className={classes.InFormWrapper}>
          <img
            className={classes.UserImage}
            src={edit ? BlackListEdit : BlackListAdd}
            alt="Изображение формы"
          />
          <div className={classes.Title}>
            {!edit ? 'Добавить в черный список' : 'Редактирование записи'}
          </div>

          <Input
            type={'text'}
            value={data.carNumber}
            placeHolder={'Государственный регистрационный знак'}
            onChangeHandler={inputHandler}
            fieldName={'carNumber'}
            itemValidationResult={getValidationResult('carNumber')}
            onBlur={setDirty}
          />

          <Input
            type={'text'}
            value={data.comment}
            placeHolder={'Комментарий'}
            onChangeHandler={inputHandler}
            fieldName={'comment'}
            itemValidationResult={getValidationResult('comment')}
            onBlur={setDirty}
          />

          {currentUser.super && (
            <Select
              options={[
                {
                  id: '0',
                  name: 'type',
                  hidden: true,
                  label: getPartnerList().length> 0 ?  'Партнер' : 'Нет партнеров',
                  selected: true,
                },
                ...getPartnerList(),
              ]}
              value={data.partnerId}
              onChangeHandler={inputHandler}
              fieldName={'partnerId'}
              type={'select'}
              itemValidationResult={getValidationResult('partnerId')}
              onBlur={setDirty}
            />
          )}

          {data.partnerId && (
            <Select
              disabled={getParkingList().length === 0}
              options={[
                {
                  id: '0',
                  name: 'type',
                  hidden: true,
                  label:
                    getParkingList().length > 0 ? 'Парковка' : 'Нет парковок',
                  selected: true,
                },
                ...getParkingList(),
              ]}
              value={data.parkingId}
              onChangeHandler={inputHandler}
              fieldName={'parkingId'}
              type={'select'}
              itemValidationResult={getValidationResult('parkingId')}
              onBlur={setDirty}
            />
          )}

          <CheckBox
            title={'Активна'}
            value={data.active}
            onChangeHandler={inputHandler}
            fieldName={'active'}
            type={'checkbox'}
          />

          <div className={classes.ButtonsContainer}>
            <Button
              wide
              text={!edit ? 'Добавить запись' : 'Сохранить изменения'}
              onClick={createOrUpdateAction}
              disabled={disabled}
            />
            {edit && (
              <Button
                text="Удалить запись"
                red
                wide
                onClick={() => setInProgress(true)}
                disabled={!checkAbility(Action.Delete, Subject.BlackList)}
              />
            )}
            {!edit && (
              <Button
                wide
                light
                text={'Отмена'}
                onClick={() => setModalActive(false)}
              />
            )}
          </div>
        </div>
      </div>
    )
  return (
    <div className={classes.Container} onClick={(e) => e.stopPropagation()}>
      <div className={classes.InFormWrapper}>
        <img
          className={classes.ImageSure}
          src={are_you_sure}
          alt={'Подтверждение действия'}
        />
        <div className={classes.TitleSure}>Вы уверены? </div>
        <div className={classes.CommentSure}>
          При удалении записи последствия...
        </div>
        <div className={classes.ButtonsContainer}>
          <Button wide text={'Подтвердить'} onClick={deleteAction} />

          <Button
            wide
            light
            text={'Отменить'}
            onClick={() => setModalActive(false)}
          />
        </div>
      </div>
    </div>
  )
}
