import React, { useEffect } from 'react'
import classes from './Form.module.sass'
import UserCircleEdit from '../../../../assets/images/icons/UserCircleEdit.svg'
import UserCircleAdd from '../../../../assets/images/icons/UserCircleAdd.svg'
import { Input } from '../../../../common/components/input/Input'
import { Button } from '../../../../common/components/button/Button'
import { useForm } from './hooks/useForm'
import are_you_sure from '../../../../assets/images/icons/are_you_sure.svg'
import { CheckBox } from '../../../../common/components/checkBox/CheckBox'
import { FilterWithGroupSelection } from '../../../../common/components/filterWithGroupSelection/FilterWithGroupSelection'
import {
  Action,
  Subject,
  UserInterface,
} from '../../../../common/interfaces/interfaces'
import { Select } from '../../../../common/components/select/Select'

type Props = {
  edit?: boolean
  setModalActive: React.Dispatch<React.SetStateAction<boolean>>
  initialValue: UserInterface
  getList: () => void
}

export const Form = ({
  edit,
  setModalActive,
  initialValue,
  getList,
}: Props) => {
  const {
    inProgress,
    setInProgress,
    inputHandler,
    data,
    createOrUpdateAction,
    deleteAction,
    filteredParkingList,
    getValidationResult,
    disabled,
    setDirty,
    getPartnerList,
    currentUser,
    getRolesListForSelect,
    checkAbility,
  } = useForm(initialValue, getList)

  if (!inProgress)
    return (
      <div className={classes.Container} onClick={(e) => e.stopPropagation()}>
        <div className={classes.InFormWrapper}>
          <img
            className={classes.UserImage}
            src={edit ? UserCircleEdit : UserCircleAdd}
            alt="Изображение формы"
          />
          <div className={classes.Title}>
            {!edit ? 'Новый пользователь' : 'Редактирование'}
          </div>
          <Input
            type={'text'}
            value={data.fio}
            placeHolder={'Фамилия Имя Отчество'}
            onChangeHandler={inputHandler}
            fieldName={'fio'}
            itemValidationResult={getValidationResult('fio')}
            onBlur={setDirty}
          />
          <Input
            type={'text'}
            value={data.login}
            placeHolder={'Логин'}
            onChangeHandler={inputHandler}
            fieldName={'login'}
            itemValidationResult={getValidationResult('login')}
            onBlur={setDirty}
          />
          <Input
            type={'password'}
            value={data.password ? data.password : ''}
            placeHolder={'Пароль'}
            onChangeHandler={inputHandler}
            fieldName={'password'}
            itemValidationResult={getValidationResult('password')}
            onBlur={setDirty}
          />
          <Input
            type={'text'}
            value={data.email}
            placeHolder={'Email'}
            onChangeHandler={inputHandler}
            fieldName={'email'}
            itemValidationResult={getValidationResult('email')}
            onBlur={setDirty}
          />

          {currentUser.super && (
            <CheckBox
              title={'Суперпользователь'}
              value={data.super}
              onChangeHandler={inputHandler}
              fieldName={'super'}
              type={'checkbox'}
            />
          )}

          {!data.super && currentUser.super && (
            <Select
              options={[
                {
                  id: '0',
                  name: 'type',
                  hidden: true,
                  label:
                    getPartnerList().length > 0
                      ? 'Выберите партнера'
                      : 'Нет партнеров',
                },
                ...getPartnerList(),
              ]}
              value={data.partnerId}
              onChangeHandler={inputHandler}
              fieldName={'partnerId'}
              type={'select'}
              itemValidationResult={getValidationResult('partnerId')}
              onBlur={setDirty}
              label="Партнер"
            />
          )}

          {getRolesListForSelect().length > 0 && !data.super && (
            <Select
              disabled={getRolesListForSelect().length === 0}
              options={[
                {
                  id: '0',
                  name: 'type',
                  hidden: true,
                  label:
                    getRolesListForSelect().length > 0
                      ? 'Выберeте роль'
                      : 'Нет ролей',
                },
                ...getRolesListForSelect(),
              ]}
              value={data.roleId}
              onChangeHandler={inputHandler}
              fieldName={'roleId'}
              type={'select'}
              itemValidationResult={getValidationResult('roleId')}
              onBlur={setDirty}
              label="Роль"
            />
          )}

          {!data.super && data.partnerId && (
            <FilterWithGroupSelection
              width100
              emptyText="Выберите парковку"
              label="Доступные парковки"
              allText="Все парковки"
              initialValue={filteredParkingList}
              inputHandler={inputHandler}
              fieldName="parkingList"
              selected={data.parkingList}
              itemValidationResult={getValidationResult('parkingList')}
              onBlur={setDirty}
            />
          )}

          <CheckBox
            title={'Активен'}
            value={data.active}
            onChangeHandler={inputHandler}
            fieldName={'active'}
            type={'checkbox'}
          />

          <div className={classes.ButtonsContainer}>
            <Button
              wide
              text={
                !edit ? 'Добавить нового пользователя' : 'Сохранить изменения'
              }
              onClick={createOrUpdateAction}
              disabled={disabled}
            />
            {edit && (
              <Button
                text="Удалить пользователя"
                red
                onClick={() => setInProgress(true)}
                disabled={
                  data.id === currentUser.id ||
                  !checkAbility(Action.Delete, Subject.Users)
                }
              />
            )}
            {!edit && (
              <Button
                wide
                light
                text={'Отмена'}
                onClick={() => setModalActive(false)}
              />
            )}
          </div>
        </div>
      </div>
    )
  return (
    <div className={classes.Container} onClick={(e) => e.stopPropagation()}>
      <div className={classes.InFormWrapper}>
        <img
          className={classes.ImageSure}
          src={are_you_sure}
          alt={'Подтверждение действия'}
        />
        <div className={classes.TitleSure}>Вы уверены? </div>
        <div className={classes.CommentSure}>
          При удалении пользователя последствия...
        </div>
        <div className={classes.ButtonsContainer}>
          <Button wide text={'Подтвердить'} onClick={deleteAction} />
          <Button
            wide
            light
            text={'Отменить'}
            onClick={() => setModalActive(false)}
          />
        </div>
      </div>
    </div>
  )
}
