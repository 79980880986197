import React from 'react'
import classes from './ListLoader.module.sass'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export const ListLoader = () => {
  return (
    <div className={classes.Container}>
      <Skeleton count={5} height={74} width={'95%'} />
    </div>
  )
}
