import React from 'react'
import classes from './Form.module.sass'
import { Input } from '../../../../common/components/input/Input'
import { Button } from '../../../../common/components/button/Button'
import { useForm } from './hooks/useForm'
import { PartnerInterface } from '../../../../common/interfaces/interfaces'
import { CheckBox } from '../../../../common/components/checkBox/CheckBox'
import UserCircleForm from '../../../../assets/images/icons/UserCircleForm.svg'
import UserCircleFormEdit from '../../../../assets/images/icons/UserCircleFormEdit.svg'
import deletePartner from '../../../../assets/images/formImages/deletePartner.svg'

type Props = {
  edit?: boolean
  setModalActive: React.Dispatch<React.SetStateAction<boolean>>
  initialValue: PartnerInterface
  getList: () => void
}

export const Form = ({
  edit,
  setModalActive,
  initialValue,
  getList,
}: Props) => {
  const {
    inProgress,
    setInProgress,
    inputHandler,
    data,
    createOrUpdateAction,
    deleteAction,
    getValidationResult,
    disabled,
    setDirty,
  } = useForm(initialValue, getList)

  if (!inProgress)
    return (
      <div className={classes.Container} onClick={(e) => e.stopPropagation()}>
        <div className={classes.InFormWrapper}>
          <img
            className={classes.UserImage}
            src={!edit ? UserCircleForm : UserCircleFormEdit}
            alt="Изображение формы"
          />
          <div className={classes.Title}>
            {!edit ? 'Новый партнер' : 'Редактирование партнера'}
          </div>

          <Input
            type={'text'}
            value={data.name}
            placeHolder={'Наименование'}
            onChangeHandler={inputHandler}
            fieldName={'name'}
            itemValidationResult={getValidationResult('name')}
            onBlur={setDirty}
          />
          <Input
            type={'text'}
            value={data.itn}
            placeHolder={'ИНН'}
            onChangeHandler={inputHandler}
            fieldName={'itn'}
            itemValidationResult={getValidationResult('itn')}
            onBlur={setDirty}
          />
          <Input
            type={'text'}
            value={data.phone}
            placeHolder={'Телефон'}
            onChangeHandler={inputHandler}
            fieldName={'phone'}
            itemValidationResult={getValidationResult('phone')}
            onBlur={setDirty}
          />
          <Input
            type={'text'}
            value={data.address}
            placeHolder={'Адрес'}
            onChangeHandler={inputHandler}
            fieldName={'address'}
            itemValidationResult={getValidationResult('address')}
            onBlur={setDirty}
          />
          <Input
            type={'text'}
            value={data.contactPerson}
            placeHolder={'Контактное лицо'}
            onChangeHandler={inputHandler}
            fieldName={'contactPerson'}
            itemValidationResult={getValidationResult('contactPerson')}
            onBlur={setDirty}
          />

          <CheckBox
            title={'Активен'}
            value={data.active}
            onChangeHandler={inputHandler}
            fieldName={'active'}
            type={'checkbox'}
          />

          <div className={classes.ButtonsContainer}>
            <Button
              wide
              text={!edit ? 'Добавить партнера' : 'Сохранить изменения'}
              onClick={createOrUpdateAction}
              disabled = {disabled}
            />
            {edit && (
              <Button
                text="Удалить партнера"
                red
                wide
                onClick={() => setInProgress(true)}
              />
            )}
            {!edit && (
              <Button
                wide
                light
                text={'Отмена'}
                onClick={() => setModalActive(false)}
              />
            )}
          </div>
        </div>
      </div>
    )
  return (
    <div className={classes.Container} onClick={(e) => e.stopPropagation()}>
      <div className={classes.InFormWrapper}>
        <img
          className={classes.ImageSure}
          src={deletePartner}
          alt={'Подтверждение действия'}
        />
        <div className={classes.TitleSure}>Вы уверены? </div>
        <div className={classes.CommentSure}>
          При удалении партнера последствия...
        </div>
        <div className={classes.ButtonsContainer}>
          <Button wide text={'Подтвердить'} onClick={deleteAction} />
          <Button
            wide
            light
            text={'Отменить'}
            onClick={() => setModalActive(false)}
          />
        </div>
      </div>
    </div>
  )
}
