import { configureStore } from '@reduxjs/toolkit'
import currentUserSlice from './slices/currentUserSlice'
import authSlice from './slices/authSlice'
import parkingListSlice from './slices/parkingListSlice'
import partnerListSlice from './slices/partnerListSlice'
import  notificationListSlice  from './slices/notificationSlice'
import deviceStateListSlice from './slices/deviceStateListSlice'

export const store = configureStore({
  reducer: {
    currentUser: currentUserSlice,
    auth: authSlice,
    parkingList: parkingListSlice,
    partnerList: partnerListSlice,
    notificationList: notificationListSlice,
    deviceStateSList: deviceStateListSlice,
  },
})

export type RootState = ReturnType<typeof store.getState>
