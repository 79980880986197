import { useState, useEffect } from 'react'

const rangeLength = 3

export const usePagination = (limit: number, totalCount: number) => {
  const [range, setRange] = useState(0)
  const [currentStep, setCurrentStep] = useState(1)
  const [nextDisabled, setNextDisabled] = useState(true)
  const [prevDisabled, setPrevDisabled] = useState(true)

  const nextRange = () => setRange((prev) => prev + rangeLength)
  const prevRange = () => setRange((prev) => prev - rangeLength)

  const calculateSteps = () => {
    const steps: number[] = []
    let i = 0
    while (i < totalCount / limit) {
      steps.push(i + 1)
      i++
    }
    return steps
  }

 const numberOfSteps = calculateSteps().length

  useEffect(() => {
    setPrevDisabled(range === 0)
    //сколько прошли + (дапазон на остаткшагов)
    setNextDisabled( calculateSteps().length <= rangeLength || range === calculateSteps().length-1 )
  }, [range, rangeLength, totalCount])

  return {
    range,
    rangeLength,
    nextRange,
    prevRange,
    currentStep,
    setCurrentStep,
    calculateSteps,
    prevDisabled,
    nextDisabled,
  }
}
