import React from 'react'
import classes from './PartnerItem.module.sass'
import { PartnerInterface } from '../../../../common/interfaces/interfaces'
import { ListButton } from '../../../../common/components/listButton/ListButton'
import nav from '../../../../assets/images/icons/nav.svg'
import { Status } from '../../../../common/components/status/Status'
import { Shorten } from '../../../../common/components/shorten/Shorten'

type Props = {
  el: PartnerInterface
  startEdit: (item: PartnerInterface) => void
}

export const PartnerItem = ({ el, startEdit }: Props) => {
  return (
    <div className={classes.Container}>
      <div className={classes.InItemWrapper}>
        <div className={classes.ContentWrapper}>
          <div className={classes.Id}>
            <Shorten id={el.id ? el.id : ''} />
          </div>

          <div className={classes.Name}>{el.name}</div>
          <div className={classes.Address}>
            <img src={nav} alt={'Иконка адреса'} className={classes.NavIcon} />
            {el.address}
          </div>
          <div className={classes.Phone}>{el.phone}</div>
          {/* <div className={classes.Itn}>
            <span className={classes.ItnTitle}>ИНН:</span>
            {el.itn}
          </div> */}
          <div className={classes.Contact}>{el.contactPerson}</div>
        </div>
        <div className={classes.ButtonAndActiveContainer}>
          <div className={classes.Active}>
            <Status
              title={{ positive: 'Активен', negative: 'Не активен' }}
              active_props={el.active}
            />
          </div>
          <div className={classes.VerticalLine} />
          <div className={classes.ButtonContainer}>
            <ListButton
              title={'Редактировать'}
              type={'edit'}
              onClick={() => startEdit(el)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
