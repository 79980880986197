import { useEffect } from 'react'
import { Action, Subject } from '../interfaces/interfaces'
import {  createMongoAbility } from '@casl/ability'
import { useCurrentUser } from './useCurrentUser'

export const useAbility = () => {
  const { currentUser } = useCurrentUser()

  

  const checkAbility = (action: Action, subject: Subject) => {
    if (currentUser?.abilities?.length > 0) {
      const ability = createMongoAbility<[Action, Subject]>(
        currentUser.abilities,
      )
      return ability.can(action, subject)
    }
  }

  return { checkAbility }
}
