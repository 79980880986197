import classes from './Bar.module.sass'
import logo from '../../../assets/images/logo.svg'
import burger from '../../../assets/images/icons/burger.svg'
import burger_active from '../../../assets/images/icons/burger_active.svg'
import { useBar } from './hooks/useBar'
import { LOGIN_ROUTE, MAIN_ROUTE } from '../../../constants'
import { Button } from '../button/Button'
import userCircle from '../../../assets/images/icons/UserCircleWhite.svg'
import { ADV_ROUTE } from './../../../constants'
import { UserInfo } from './components/userInfo/UserInfo'

type Props = {
  menuActive?: boolean
  toggleMenu?: () => void
}

export const Bar = ({ menuActive, toggleMenu }: Props) => {
  const { navigate, location } = useBar()

  return (
    <div className={classes.Container}>
      <div className={classes.InBarWrapper}>
        <div className={classes.LeftSideContainer}>
          {location.pathname !== ADV_ROUTE && (
            <img
              className={classes.BurgerIcon}
              src={!menuActive ? burger : burger_active}
              onClick={toggleMenu}
              alt="Меню"
            />
          )}

          <div className={classes.Logo} onClick={() => navigate(MAIN_ROUTE)}>
            <img className={classes.LogoIcon} src={logo} alt="Логотип" />
          </div>
          <div className={classes.Title}>
            Автомат-Сервис. Парковочные системы - Система автоматизированных
            парковочных комплексов
          </div>
        </div>

        {location.pathname !== ADV_ROUTE && (
          <>
            {/* <Search placeHolder="Поиск по сайту" /> */}
            <UserInfo />
          </>
        )}

        {location.pathname === ADV_ROUTE && (
          <div className={classes.AdvLoginButtonContainer}>
            <Button
              text={'Вход'}
              icon={userCircle}
              onClick={() => navigate(LOGIN_ROUTE)}
            />
          </div>
        )}
      </div>
    </div>
  )
}
