import React from 'react'
import classes from './Login.module.sass'
import logo from '../../assets/images/logo.svg'
import { Button } from '../../common/components/button/Button'
import { Input } from '../../common/components/input/Input'
import { useLogin } from './hooks/useLogin'
import { NotificationList } from '../../common/notificationList/NotificationList'

export const Login = () => {
  const {
    data,
    inputHandler,
    loginAction,
    getValidationResult,
    setDirty,
    disabled,
  } = useLogin()

  return (
    <div className={classes.Container}>
      <NotificationList />
      <div className={classes.ImageBlock} />
      <div className={classes.LoginBlock}>
        <img className={classes.Logo} src={logo} alt="Логотип" />
        <div className={classes.Title}>Вход в систему</div>
        <div className={classes.InLoginWrapper}>
          <Input
            fieldName={'login'}
            type={'text'}
            value={data.login}
            placeHolder="Введите логин"
            onChangeHandler={inputHandler}
            itemValidationResult={getValidationResult('login')}
            onBlur={setDirty}
          />
          <Input
            fieldName={'password'}
            type={'password'}
            value={data.password}
            placeHolder="Введите пароль"
            onChangeHandler={inputHandler}
            itemValidationResult={getValidationResult('password')}
            onBlur={setDirty}
          />
          <Button
            wide
            text={'Войти'}
            onClick={loginAction}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  )
}
