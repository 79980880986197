import React from "react";
import classes from "./Pagination.module.sass";
import { usePagination } from "./hooks/usePagination";
import CaretRightArrow from "../../../assets/images/icons/CaretRightArrow.svg";

interface PaginationProps {
  totalCount: number;
  setOffset: (value: number) => void;
  offset: number;
  limit: number;
}

export const Pagination: React.FC<PaginationProps> = ({
  totalCount,
  limit,
  setOffset,
}) => {
  const {
    range,
    rangeLength,
    nextRange,
    prevRange,
    currentStep,
    setCurrentStep,
    calculateSteps,
    prevDisabled,
    nextDisabled,
  } = usePagination(limit, totalCount);


  return (
    <div className={classes.PaginationBlock}>
      <button
        className={classes.PaginationButton}
        onClick={prevRange}
        disabled={prevDisabled}
      >
        <img
          className={classes.CaretLeftArrow}
          src={CaretRightArrow}
          alt="Предыдущий диапазон страниц"
        />
      </button>
      {calculateSteps()
        .slice(range, range + rangeLength)
        .map((step) => (
          <button
            onClick={() => {
              setOffset(step === 1 ? 0 : (step - 1) * limit);
              setCurrentStep(step);
            }}
            className={
              step === currentStep
                ? classes.PaginationButtonActive
                : classes.PaginationButton
            }
            key={step}
          >
            {step}
          </button>
        ))}
      <button
        className={classes.PaginationButton}
        onClick={nextRange}
        disabled={nextDisabled}
      >
        <img src={CaretRightArrow} alt="Следующий диапазон страниц" />
      </button>
    </div>
  );
};
