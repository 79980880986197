import React from 'react'
import classes from './SubscriptionContent.module.sass'
import { format } from 'date-fns'
import { SubscriptionItemInterface } from '../../../../../common/interfaces/interfaces'

type Props = {
  subscription: SubscriptionItemInterface | undefined
}

export const SubscriptionContent = ({ subscription }: Props) => {
  return (
    <div className={classes.Container}>
      <div className={classes.Top}>
        <div className={classes.Row}>
          <div className={classes.Name}>Название абонемента:</div>
          <div className={classes.Value}>{subscription?.name}</div>
        </div>
        <div className={classes.Row}>
          <div className={classes.Name}>Дата покупки:</div>
          <div className={classes.Value}>   {subscription?.saleDate && format(new Date(subscription?.saleDate), 'dd.MM.yyyy')}</div>
        </div>
        <div className={classes.Row}>
          <div className={classes.Name}>Срок действия:</div>
          <div className={classes.Value}>
            до{' '}
            {subscription?.expireDate && format(new Date(subscription?.expireDate), 'dd.MM.yyyy')}
          </div>
        </div>
        {/* <div className={classes.Row}>
          <div className={classes.Name}>Остаток:</div>
          <div className={classes.Value}></div>
          <div className={classes.ContainerData}>
            {subscription?.visitsLeft}{' '}
            <span className={classes.Smaller14}>визитов</span>
          </div>
        </div> */}
        <div className={classes.Row}>
          <div className={classes.Name}></div>
          <div className={classes.Value}></div>
        </div>
      </div>
      <div className={classes.Bottom}>
        <div className={classes.RowJustify}>
          <div className={classes.Name}>Стоимость абонемента:</div>
          <div className={classes.Name}>
            500<span className={classes.Rur}>руб.</span>
          </div>
        </div>
      </div>
    </div>
  )
}
