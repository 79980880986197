import { useEffect, useState } from 'react'
import {
  ParkingInterface,
  PartnerInterface,
} from '../../../interfaces/interfaces'

export const useFilterWithGroupSelection = (
  initialValue: PartnerInterface[] | ParkingInterface[],
  inputHandler: (
    value: (string | undefined)[],
    fieldName: string,
    type: string,
  ) => void,
  fieldName: string,
  selected: (string | undefined)[],
) => {
  const list = [...initialValue]

  const [expanded, setExpanded] = useState<boolean>(false)

  const [selectedItems, setSelectedItems] = useState<
    PartnerInterface[] | ParkingInterface[]
    //@ts-ignore
  >(initialValue && Array.isArray(initialValue)  ?  initialValue.filter((el) => selected.includes(el.id)) : [] )

  const toggleFilter = () => {
    setExpanded((prev) => !prev)
  }

  const resetItems = () => {
    setSelectedItems(
      selectedItems?.length === initialValue.length
        ? []
        : structuredClone(initialValue),
    )
  }

  const toggleItem = (item: PartnerInterface | ParkingInterface) => {
    setSelectedItems((prev: any) => {
      if (prev.map((el: any) => el.id).includes(item.id))
        return prev.filter((el: any) => el.id !== item.id)
      return [...prev, item]
    })
  }

  useEffect(() => {
    inputHandler(
      selectedItems.map((el) => el.id),
      fieldName,
      'array',
    )
  }, [selectedItems])

  return { expanded, toggleFilter, list, resetItems, toggleItem, selectedItems }
}
