import { useCallback, useEffect, useState } from 'react'
import {
  ParkingInterface,
  PartnerInterface,
} from './../../../common/interfaces/interfaces'
import { getApi } from '../../../api/api'
import { useLogout } from '../../../common/hooks/useLogout'
import { useCurrentUser } from '../../../common/hooks/useCurrentUser'
import { useAbility } from '../../../common/hooks/useAbility'
import { useSelector } from 'react-redux'
import { selectDeviceStateList } from '../../../store/slices/deviceStateListSlice'

const initialValuerPattern = {
  id: '',
  address: '',
  name: '',
  capacity: '',
  isHourPrice: false,
  active: false,
  cost: '',
  freeMinutesAfterPayment: '',
  partnerId: '',
}

export const useParking = () => {
  const [modalActive, setModalActive] = useState<boolean>(false)
  const [list, setList] = useState<ParkingInterface[]>([])
  const [partnerList, setPartnerList] = useState<PartnerInterface[]>([])
  const [edit, setEdit] = useState(false)
  const [initialValue, setInitialValue] = useState<ParkingInterface>(
    structuredClone(initialValuerPattern),
  )
  const [api] = useState(() => getApi())
  const { logOut } = useLogout()
  const { currentUser } = useCurrentUser()
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')
  const deviceStateList = useSelector(selectDeviceStateList)

  // Pagination ...
  const [activeCount, setActiveCount] = useState<number>(0)
  const [active, setActive] = useState<boolean>(false)
  const [offset, setOffset] = useState(0)
  const [limit] = useState(8)
  const [totalCount, setTotalCount] = useState<number>(0)

  const { checkAbility } = useAbility()

  const startEdit = (item: ParkingInterface) => {
    setEdit(true)
    setInitialValue(item)
    setModalActive(true)
  }

  const startCreate = () => {
    setEdit(false)
    setInitialValue(structuredClone(initialValuerPattern))
    setModalActive(true)
  }

  const getList = useCallback(
    async (reUsed?: boolean) => {
      try {
        if (!reUsed) {
          setList([])
          setLoading(true)
          await new Promise<void>((resolve) => setTimeout(() => resolve(), 700))
        }
        const result = await api.getParkingList({
          _start: offset.toString(),
          _end: (offset + limit).toString(),
          _sort: '_id',
          _order: 'desc',
          isActive: active ? active : '',
        })
        setModalActive(false)
        setList(result.data)
        setTotalCount(result.total)
        setActiveCount(result.totalActive)
        if (!reUsed) {
          setLoading(false)
          setMessage('Записей нет')
        }
      } catch (error: any) {
        if (error.response.status === 401) logOut().then()
        if (!reUsed) {
          setLoading(false)
          setMessage('Произошла ошибка во время запроса, попробуйте позже')
        }
      }
    },
    [
      api,
      logOut,
      limit,
      offset,
      active,
      setActiveCount,
      setTotalCount,
      currentUser.partnerId,
    ],
  )

  const getPartnerList = useCallback(async () => {
    try {
      const result = await api.getPartnerList({})
      setPartnerList(result.data)
    } catch (error: any) {
      if (error.response.status === 401) logOut().then()
    }
  }, [api, logOut])

  useEffect(() => {
    getList()
  }, [api, limit, offset, active])

  useEffect(() => {
    getPartnerList()
  }, [api])

  const toggleActive = () => {
    setActive((prev) => !prev)
  }

  const checkDeviceActive = (id: string) => {
    const device = deviceStateList.find((el) => el.deviceId === id)
    if (device) return true
    return false
  }

  const getPartnerName = (partnerId: string) => {
    
    const role = partnerList.find((el) => el.id === partnerId)?.name
    if (role) return role
    return 'Партнер не найден'
  }

  return {
    list,
    modalActive,
    setModalActive,
    edit,
    setEdit,
    initialValue,
    startEdit,
    startCreate,
    getList,
    loading,
    message,
    toggleActive,
    totalCount,
    limit,
    offset,
    setOffset,
    active,
    activeCount,
    checkAbility,
    checkDeviceActive,
    getPartnerName,
  }
}
