import React from 'react'
import classes from './BarrierPass.module.sass'
import { useBarrierPass } from './hooks/useBarrierPass'
import details from '../../../assets/images/details.svg'
import { PassOutContent } from './components/passOutContent/PassOutContent'
import { Shorten } from '../../../common/components/shorten/Shorten'

export const BarrierPass = () => {
  const { barrierPass, sale } = useBarrierPass()

  return (
    <div className={classes.Container}>
      <div className={classes.HeaderAndButtonWrapper}>
        <div className={classes.Header}>
          <div>Выезд</div>
          <Shorten id={barrierPass?.id ?? ''} />
        </div>
      </div>

      <div className={classes.ListContainer}>
        <PassOutContent barrierPassOut={barrierPass} sale={sale} />

        <img
          src={details}
          className={classes.PassImage}
          alt="Изображение страницы платежа"
        />
      </div>
    </div>
  )
}
