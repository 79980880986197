import { Navigate, Outlet } from 'react-router-dom'

import { useSelector } from 'react-redux'
import { AppWrapper } from '../../hoc/appWrapper/AppWrapper'
import { selectAuthToken } from '../../store/slices/authSlice'

export const Protected = () => {
  const token = useSelector(selectAuthToken)
  const currentUser = localStorage.getItem('currentUser')

  if (token || currentUser) {
    return (
      <AppWrapper>
        <Outlet />
      </AppWrapper>
    )
  } else {
    return <Navigate to="/login" />
  }
}
