import React from 'react'
import classes from './Paid.module.sass'
import { PaymentTypes } from '../../interfaces/interfaces'

type Props = {
  type: PaymentTypes
  click?: () => void
}

export const Paid = ({ type, click }: Props) => {
  return (
    <div
      onClick={click && click}
      className={classes.Container}

      // className={
      //   type === 'pass'
      //     ? classes.ContainerPass
      //     : type === 'bankCard'
      //     ? classes.Container
      //     : classes.ContainerNotFound
      // }
    >
      Оплата
      {/* {type === 'pass'
        ? 'Абонемент'
        : type === 'bankCard'
        ? 'Банковская карта'
        : 'Платеж не найден'} */}
    </div>
  )
}
