import React from 'react'
import classes from './CardLoader.module.sass'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export const CardLoader = () => {
  return (
    <div className={classes.Container}>
      <div className={classes.SubContainer}>
        <Skeleton count={2} height={360} width={'100%'} />
      </div>
      <div className={classes.SubContainer2}>
        <Skeleton count={2} height={360} width={'100%'} />
      </div>
      <div className={classes.SubContainer3}>
        <Skeleton count={2} height={360} width={'100%'} />
      </div>
    </div>
  )
}
