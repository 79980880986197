import { useNavigate } from 'react-router-dom'
import { LOGIN_ROUTE } from '../../constants'
import { useCallback, useState } from 'react'
import { getApi } from '../../api/api'
import { useDispatch } from 'react-redux'
import { setAuthToken } from '../../store/slices/authSlice'

export const useLogout = () => {
  const navigate = useNavigate()
  const [api] = useState(() => getApi())
  const dispatch = useDispatch()

  const logOut = useCallback(async () => {
    try {
      await api.logout()    
    } catch (error) {
    }
    dispatch(setAuthToken(''))
    localStorage.removeItem('currentUser')
    navigate(LOGIN_ROUTE)
  }, [api, dispatch, navigate])

  return { logOut }
}
