import React from 'react'
import classes from './Toggle.module.sass'
import toggle_checked from '../../../assets/images/icons/toggle_checked.svg'
import toggle_unchecked from '../../../assets/images/icons/toggle_unchecked.svg'
import { Action, Subject } from '../../interfaces/interfaces'

type Props = {
  on_props: boolean
  action: Action
  subject: Subject
  onChangeHandler: (action: Action, subject: Subject) => void
  disabled: boolean
}

export const Toggle = ({
  on_props,
  onChangeHandler,
  action,
  subject,
  disabled,
}: Props) => {
  // const { toggleHandler, on } = useToggle(on_props)

  return (
    <div className={disabled ? classes.ToDisableDisabled : classes.ToDisable}>
      <div
        className={!on_props ? classes.ContainerDisabled : classes.Container}
      >
        <div
          className={
            !on_props
              ? classes.InToggleWrapperDisabled
              : classes.InToggleWrapper
          }
        >
          {!on_props && <img src={toggle_unchecked} alt="Выключено" />}
          <div
            className={classes.Switch}
            onClick={() => {
              !disabled && onChangeHandler(action, subject)
            }}
          />
          {on_props && <img src={toggle_checked} alt="Включено" />}
        </div>
      </div>
    </div>
  )
}
