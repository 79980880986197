import React from 'react'
import classes from './RoleItem.module.sass'
import {
  Action,
  RoleInterface,
  Subject,
} from '../../../../common/interfaces/interfaces'
import { ListButton } from '../../../../common/components/listButton/ListButton'
import { format } from 'date-fns'
import { useAbility } from '../../../../common/hooks/useAbility'
import { Shorten } from '../../../../common/components/shorten/Shorten'

type Props = {
  el: RoleInterface
  startEdit: (el: RoleInterface) => void
}

export const RoleItem = ({ el, startEdit }: Props) => {
  const { checkAbility } = useAbility()

  return (
    <div className={classes.Container}>
      <div className={classes.ContentWrapper}>
        <div className={classes.Value}>
          <Shorten id={el.id ? el.id : ''} />
        </div>
        <div className={classes.Name}>
          <span className={classes.NameTitle}>Наименование:</span> {el.name}
        </div>
        <div className={classes.DateTime}>
          <span className={classes.DateTitle}>Дата создания/изменения:</span>{' '}
          <div className={classes.DateTimeWrapper}>
            {format(new Date(el.lastChanged), 'dd.MM.yyyy')}
            <div className={classes.Time}>
              {format(new Date(el.lastChanged), 'HH:mm')}
            </div>
          </div>
        </div>
      </div>

      <div className={classes.StatusAndButtonsWrapper}>
        <div className={classes.VerticalLine} />

        <ListButton
          disabled={!checkAbility(Action.Update, Subject.Roles)}
          title={'Редактировать'}
          type={'edit'}
          onClick={() => startEdit(el)}
        />
      </div>
    </div>
  )
}
