import React from 'react'
import classes from './WhiteListItem.module.sass'
import { CarSign } from '../../../../../common/components/carSign/CarSign'
import { ListButton } from '../../../../../common/components/listButton/ListButton'
import {
  Action,
  ListItemInterface,
  Subject,
} from '../../../../../common/interfaces/interfaces'
import service_circle from '../../../../../assets/images/icons/service_circle.svg'
import { Status } from '../../../../../common/components/status/Status'
import Check from '../../../../../assets/images/icons/Check.svg'
import { useWhiteListItem } from './hooks/useWhiteListItem'
import { Shorten } from '../../../../../common/components/shorten/Shorten'

type Props = {
  listItem: ListItemInterface
  startEdit: (listItem: ListItemInterface) => void
  toGroup: (el: ListItemInterface) => void
  inGroup: boolean
  getList?: ((reUsed: boolean) => Promise<void>) | undefined
}

export const WhiteListItem = ({
  listItem,
  startEdit,
  toGroup,
  inGroup,
  getList,
}: Props) => {
  const { updateStatus, checkAbility } = useWhiteListItem(listItem, getList)

  return (
    <div className={classes.Container}>
      <div className={classes.ContentWrapper}>
        <div
          onClick={() => toGroup(listItem)}
          className={
            !inGroup ? classes.UnCheckedContainer : classes.CheckedContainer
          }
        >
          {inGroup && <img src={Check} alt="Чек бокс" />}
        </div>

        <CarSign number={listItem.carNumber} />
        <div className={classes.Comment}>{listItem.comment}</div>
        <div className={classes.ParkingId}>
          id парковки:{' '}
          <span className={classes.Id}>
            <Shorten id={listItem.parkingId} />
          </span>
        </div>
        {listItem.service && (
          <div className={classes.Service}>
            <img src={service_circle} alt="Служебный" />
            Служебный
          </div>
        )}
      </div>

      <div className={classes.StatusAndButtonsWrapper}>
        <div className={classes.Active}>
          <Status
            pointer
            toggle
            active_props={listItem.active}
            title={{ positive: 'Активна', negative: 'Не активна' }}
            fixedWith="130px"
            onClick={updateStatus}
          />
        </div>
        <div className={classes.VerticalLine} />
        <ListButton
          disabled={!checkAbility(Action.Update, Subject.WhiteList)}
          title={'Редактировать'}
          type={'edit'}
          onClick={() => startEdit(listItem)}
        />
      </div>
    </div>
  )
}
