import React from 'react'
import classes from './Roles.module.sass'
import { PageHeader } from '../../common/components/pageHeader/PageHeader'
import { useRoles } from './hooks/useRoles'
import { RoleItem } from './components/roleItem/RoleItem'
import { Modal } from '../../common/components/modal/Modal'
import { Form } from './components/form/Form'
import { Button } from '../../common/components/button/Button'
import PlusCircle from '../../assets/images/icons/PlusCircle.svg'
import { ListLoader } from '../../common/components/listLoader/ListLoader'
import { Pagination } from '../../common/components/pagination/Pagination'
import { Action, Subject } from '../../common/interfaces/interfaces'

export const Roles = () => {
  const {
    list,
    modalActive,
    setModalActive,
    startEdit,
    edit,
    startCreate,
    totalCount,
    limit,
    offset,
    setOffset,
    loading,
    message,
    getList,
    initialValue,
    checkAbility,
  } = useRoles()

  return (
    <div className={classes.Container}>
      <div className={classes.HeaderAndButtonWrapper}>
        <div className={classes.BookmarksAndAdd}>
          <PageHeader />
          <Button
            text="Добавить"
            icon={PlusCircle}
            onClick={() => startCreate()}
            disabled={!checkAbility(Action.Create, Subject.Roles)}
          />
        </div>
      </div>

      <div className={classes.RolesListContainer}>
        {list.length > 0 ? (
          list.map((el) => (
            <RoleItem key={el.id} el={el} startEdit={startEdit} />
          ))
        ) : !loading ? (
          <div className={classes.LoaderContainer}>
            <span className={classes.Message}>{message}</span>
          </div>
        ) : (
          <ListLoader />
        )}
      </div>

      <Pagination
        totalCount={totalCount}
        limit={limit}
        offset={offset}
        setOffset={setOffset}
      />

      <Modal modalActive={modalActive} setModalActive={setModalActive}>
        <Form
          setModalActive={setModalActive}
          initialValue={initialValue}
          edit={edit}
          getList={getList}
        />
      </Modal>
    </div>
  )
}
