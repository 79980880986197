import React from 'react'
import {   SalesReportItemInterface } from '../../../../../common/interfaces/interfaces'
import classes from './ParkingItem.module.sass'
import caret_right from '../../../../../assets/images/icons/caret_right_blue_circle.svg'
import caret_down from '../../../../../assets/images/icons/caret_down_blue_circle.svg'
import { useParkingItem } from './hooks/useParkingItem'
import { SalesItem } from '../salesItem/SalesItem'

type Props = {
  el: SalesReportItemInterface
}

export const ParkingItem = ({ el }: Props) => {
  const { expanded, toggleLevel } = useParkingItem()

  return (
    <div className={classes.ContainerAndExpanded}>
      <div className={classes.Container} onClick={toggleLevel}>
        <div className={classes.LeftContentContainer}>
          <img
            src={expanded ? caret_down : caret_right}
            alt={'Развернуть уровень отчета'}
          />
          <div>Парковка: #{el.parkingId}</div>
          {/* <div className={classes.Adress}>
            <img src={nav} alt="Адрес" />
            {el.address}
          </div> */}
        </div>
      </div>
      {expanded && (
        <div className={classes.ItemsList}>
          {el.data.map((item) => (
            <SalesItem key={item.id} item={item} />
          ))}
        </div>
      )}
    </div>
  )
}
