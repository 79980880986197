import React from 'react'
import classes from './SalesList.module.sass'
import { PageHeader } from '../../common/components/pageHeader/PageHeader'
import { useSalesList } from './hooks/useSalesList'
import { SalesListItem } from './components/salesListItem/SalesListItem'
import sale_small from '../../assets/images/icons/sale_small.svg'
import { Modal } from '../../common/components/modal/Modal'
import { Form } from './components/form/Form'
import { Filter } from '../../common/components/filter/Filter'
import { ListLoader } from '../../common/components/listLoader/ListLoader'
import { Pagination } from '../../common/components/pagination/Pagination'
import { ActivateBarrier } from '../../common/components/activateBarrier/ActivateBarrier'
import { Way } from '../../common/interfaces/interfaces'

export const SalesList = () => {
  const {
    list,
    modalActive,
    setModalActive,
    startPaymentClosing,
    initialValue,
    getList,
    current,
    gte,
    lte,
    setFrom,
    setTo,
    loading,
    message,
    limit,
    offset,
    setOffset,
    totalCount,
    disabled,
    dateMessage,
    modalActive1,
    setModalActive1,
    startBarrierOpening,
    parkingId,
  } = useSalesList()

  return (
    <div className={classes.Container}>
      <div className={classes.HeaderAndButtonWrapper}>
        <PageHeader length={totalCount} icon_props={sale_small} />
      </div>
      <Filter
        gte={gte}
        lte={lte}
        setFrom={setFrom}
        setTo={setTo}
        getReport={getList}
        disabled={disabled}
        dateMessage={dateMessage}
      />
      <div className={classes.ListContainer}>
        {list.length > 0 ? (
          list.map((el) => (
            <SalesListItem
              key={el.id}
              el={el}
              startPaymentClosing={startPaymentClosing}
              startBarrierOpening={startBarrierOpening}
            />
          ))
        ) : !loading ? (
          <div className={classes.LoaderContainer}>
            <span className={classes.Message}>{message}</span>
          </div>
        ) : (
          <ListLoader />
        )}
      </div>

      <Pagination
        totalCount={totalCount}
        limit={limit}
        offset={offset}
        setOffset={setOffset}
      />

      <Modal modalActive={modalActive} setModalActive={setModalActive}>
        <Form
          setModalActive={setModalActive}
          initialValue={initialValue}
          getList={getList}
          current={current}
        />
      </Modal>
      <Modal
        cantClose
        central
        modalActive={modalActive1}
        setModalActive={setModalActive1}
      >
        <ActivateBarrier
          way={Way.out}
          setModalActive={setModalActive1}
          current={current}
          getList={getList}
          parkingId={parkingId}
        />
      </Modal>
    </div>
  )
}
