import {
  BARRIER_LOGS_ROUTE,
  BLACK_LIST_ROUTE,
  LOCAL_DEVICE_ROUTE,
  MAIN_ROUTE,
  PARKING_ROUTE,
  PARTNERS_ROUTE,
  ROLES_ROUTE,
  SALES_LIST_ROUTE,
  SUBSCRIPTIONS_ROUTE,
  USERS_ROUTE,
  WHITE_LIST_ROUTE,
} from '../../../constants'
import { Subject } from '../../interfaces/interfaces'

import { SALES_ROUTE } from './../../../constants'
import { MenuLink } from './interfaces'

export const menuLinks: MenuLink[] = [
  {
    id: 1,
    name: 'Главная',
    subject: Subject.Skip,
    link: MAIN_ROUTE,
    subLinks: [],
  },
  {
    id: 2,
    name: 'Справочники',
    subject: Subject.Skip,
    link: '',
    subLinks: [
      {
        id: 2,
        name: 'Пользователи',
        subject: Subject.Users,
        link: USERS_ROUTE,
        subLinks: [],
      },
      {
        id: 3,
        name: 'Партнеры',
        subject: Subject.Partners,
        link: PARTNERS_ROUTE,
        subLinks: [],
      },
      {
        id: 4,
        subject: Subject.Parking,
        name: 'Парковки',
        link: PARKING_ROUTE,
        subLinks: [],
      },
      {
        id: 6,
        name: 'Роли',
        subject: Subject.Roles,
        link: ROLES_ROUTE,
        subLinks: [],
      },
      {
        id: 7,
        name: 'Устройства',
        subject: Subject.LocalDevices,
        link: LOCAL_DEVICE_ROUTE,
        subLinks: [],
      },
    ],
  },
  {
    id: 3,
    name: 'Журналы',
    subject: Subject.Skip,
    link: '',
    subLinks: [
      {
        id: 1,
        name: 'Журнал шлагбаумов',
        link: BARRIER_LOGS_ROUTE,
        subject: Subject.BarrierPasses,
        subLinks: [],
      },
      {
        id: 2,
        name: 'Журнал продаж',
        link: SALES_LIST_ROUTE,
        subject: Subject.Sales,
        subLinks: [],
      },
    ],
  },
  {
    id: 4,
    name: 'Отчеты',
    subject: Subject.Skip,
    link: '',
    subLinks: [
      {
        id: 1,
        name: 'Продажи',
        link: SALES_ROUTE,
        subject: Subject.SalesReport,
        subLinks: [],
      },
    ],
  },
  {
    id: 5,
    name: 'Абонементы',
    subject: Subject.Subscriptions,
    link: SUBSCRIPTIONS_ROUTE,
    subLinks: [],
  },
  {
    id: 6,
    name: 'Списки',
    link: '',
    subject: Subject.Skip,
    subLinks: [
      {
        id: 1,
        name: 'Черный список',      
        link: BLACK_LIST_ROUTE,
        subject: Subject.BlackList,
        subLinks: [],
      },
      {
        id: 2,
        name: 'Белый список',      
        link: WHITE_LIST_ROUTE,
        subject: Subject.WhiteList,
        subLinks: [],
      },
    ],
  },
]
