import { useLocation, useNavigate } from 'react-router-dom'
import { useAbility } from '../../../../../../../hooks/useAbility'
import { Action, Subject } from '../../../../../../../interfaces/interfaces'
import { MenuSubLnk } from '../../../../../interfaces'

export const useMenuSubItem = (link: MenuSubLnk) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { checkAbility } = useAbility()
  const available =
    link.subject !== Subject.Skip
      ? checkAbility(Action.Read, link.subject)
      : true

  return { navigate, location,available }
}
