import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  NotificationInterface,
  NotificationList,
} from '../../common/interfaces/interfaces'
import { RootState } from '../store'

const initialState: NotificationList = {
  data: [],
}

export const notificationListSlice = createSlice({
  name: 'notification-list',
  initialState,
  reducers: {
    setNotificationList: (
      state,
      action: PayloadAction<NotificationInterface[]>,
    ) => {
      state.data = action.payload
    },
    addNotification: (state, action: PayloadAction<NotificationInterface>) => {
      if (!state.data.find((el) => el.text === action.payload.text))
        state.data = [...state.data, action.payload]
    },
    removeNotification: (
      state,
      action: PayloadAction<NotificationInterface>,
    ) => {
      state.data = state.data.filter((el) => el.id !== action.payload.id)
    },
    removeAllNotifications: (state, action: PayloadAction) => {
      state.data = []
    },
  },
})

export const { setNotificationList } = notificationListSlice.actions
export const { removeNotification } = notificationListSlice.actions
export const { removeAllNotifications } = notificationListSlice.actions
export const { addNotification } = notificationListSlice.actions
export const selectNotificationList = (state: RootState) =>
  state.notificationList.data

export default notificationListSlice.reducer
