import { useState } from "react";

export const useDashBoardItem = () => {
  const [firstSide, setFirstSide] = useState<boolean>(true);

  const sideHandler = () => {
    setFirstSide((prev) => !prev);
  };

  return { firstSide, sideHandler };
};
