import React, { SetStateAction, useState } from 'react'
import { ValidationRulesInterface } from '../interfaces/interfaces'

export const useInput = <T>(
  setData: React.Dispatch<SetStateAction<T>>,
  checkAndAddValidation?: (fieldName: string) => void,
) => {
  const inputHandler = (
    value: string | boolean | number | (string | undefined)[],
    fieldName: string,
    type: string,
  ) => {
    checkAndAddValidation && checkAndAddValidation(fieldName)
    if (type === 'array' && Array.isArray(value)) {
      
      setData((prevState) => ({
        ...prevState,
        [fieldName]: [...value],
      }))
    } else if (type === 'checkbox') {
      setData((prevState) => ({
        ...prevState,
        //@ts-ignore
        [fieldName]: !prevState[fieldName],
      }))
    } else if (type === 'number') {
      setData((prevState) => ({
        ...prevState,
        [fieldName]: Number(value),
      }))
    } else if (type === 'Date') {
      setData((prevState) => ({
        ...prevState,
        [fieldName]: value.toString(),
      }))
    } else {
      setData((prevState) => ({
        ...prevState,
        [fieldName]: value,
      }))
    }
  }

  return { inputHandler }
}
