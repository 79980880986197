import React from 'react'
import classes from './FilterWithGroupSelectionItem.module.sass'
import Check from '../../../../../assets/images/icons/Check.svg'
import {
  ParkingInterface,
  PartnerInterface,
} from '../../../../interfaces/interfaces'

type Props = {
  el: PartnerInterface | ParkingInterface
  checked: boolean
  toggleItem: (item: PartnerInterface | ParkingInterface) => void
}

export const FilterWithGroupSelectionItem = ({
  el,
  checked,
  toggleItem,
}: Props) => {
  return (
    <div
      className={classes.Container}
      onClick={() => {
        toggleItem(el)
      }}
    >
      <div
        className={
          !checked ? classes.UnCheckedContainer : classes.CheckedContainer
        }
      >
        {checked && <img src={Check} />}
      </div>
      <div>{el.name}</div>
    </div>
  )
}
