import React from "react";
import { ParkingInterface } from "../../../../../../common/interfaces/interfaces";
import classes from '../../DashBoardItem.module.sass'
import { DeviceItem } from "./components/deviceItem/DeviceItem";

type Props = {
  parking: ParkingInterface;
};

export const Side2 = ({ parking }: Props) => {
  return (
    <div className={classes.DevicesContainer}>
      {/* {parking.devices && parking.devices.length > 0 &&
        parking.devices.map((device) => (
          <DeviceItem
            device={device}
            key={device.innerId}
            sensors={device.sensors}
          />
        ))} */}
    </div>
  );
};
