import React from 'react'
import classes from './Form.module.sass'
import { Input } from '../../../../common/components/input/Input'
import { Button } from '../../../../common/components/button/Button'
import { useForm } from './hooks/useForm'
import are_you_sure from '../../../../assets/images/icons/are_you_sure.svg'
import AbonCircleADD from '../../../../assets/images/icons/AbonCircleADD.svg'
import AbonCircleCHANGE from '../../../../assets/images/icons/AbonCircleCHANGE.svg'
import { CheckBox } from '../../../../common/components/checkBox/CheckBox'
import {
  Action,
  Subject,
  SubscriptionItemInterface,
} from '../../../../common/interfaces/interfaces'
import { Select } from '../../../../common/components/select/Select'

type Props = {
  edit?: boolean
  setModalActive: React.Dispatch<React.SetStateAction<boolean>>
  initialValue: SubscriptionItemInterface
  getList: () => void
}

export const Form = ({
  edit,
  setModalActive,
  initialValue,
  getList,
}: Props) => {
  const {
    inProgress,
    setInProgress,
    inputHandler,
    data,
    createOrUpdateAction,
    deleteAction,
    getValidationResult,
    disabled,
    setDirty,
    currentUser,
    getParkingList,
    getPartnerList,
    checkAbility,
  } = useForm(initialValue, getList)

  if (!inProgress)
    return (
      <div className={classes.Container} onClick={(e) => e.stopPropagation()}>
        <div className={classes.InFormWrapper}>
          <img
            className={classes.UserImage}
            src={!edit ? AbonCircleADD : AbonCircleCHANGE}
            alt="Изображение формы"
          />
          <div className={classes.Title}>
            {!edit ? 'Новый абонемент' : 'Редактирование абонемента'}
          </div>

          {currentUser.super && (
            <Select
              options={[
                {
                  id: '0',
                  name: 'type',
                  hidden: true,
                  label:
                    getPartnerList().length > 0 ? 'Партнер' : 'Нет партнеров',
                  selected: true,
                },
                ...getPartnerList(),
              ]}
              value={data.partnerId}
              onChangeHandler={inputHandler}
              fieldName={'partnerId'}
              type={'select'}
              itemValidationResult={getValidationResult('partnerId')}
              onBlur={setDirty}
            />
          )}

          {data.partnerId && (
            <Select
              disabled={getParkingList().length === 0}
              options={[
                {
                  id: '0',
                  name: 'type',
                  hidden: true,
                  label:
                    getParkingList().length > 0 ? 'Парковка' : 'Нет парковок',
                  selected: true,
                },
                ...getParkingList(),
              ]}
              value={data.parkingId}
              onChangeHandler={inputHandler}
              fieldName={'parkingId'}
              type={'select'}
              itemValidationResult={getValidationResult('parkingId')}
              onBlur={setDirty}
            />
          )}

          <Input
            type={'text'}
            value={data.name}
            placeHolder={'Наименование абонемента'}
            onChangeHandler={inputHandler}
            fieldName={'name'}
            itemValidationResult={getValidationResult('name')}
            onBlur={setDirty}
          />

          <Input
            type={'text'}
            value={data.carNumber}
            placeHolder={'Государственный регистрационный знак'}
            onChangeHandler={inputHandler}
            fieldName={'carNumber'}
            itemValidationResult={getValidationResult('carNumber')}
            onBlur={setDirty}
          />

          <Input
            type={'number'}
            value={data.availableMin}
            placeHolder={'Доступное время, мин'}
            onChangeHandler={inputHandler}
            fieldName={'availableMin'}
            itemValidationResult={getValidationResult('availableMin')}
            onBlur={setDirty}
          />

          <Input
            type={'date'}
            value={
              data.expireDate &&
              new Date(data.expireDate).toISOString().split('T')[0]
            }
            placeHolder={'Действителен до'}
            onChangeHandler={inputHandler}
            fieldName={'expireDate'}
            itemValidationResult={getValidationResult('expireDate')}
            onBlur={setDirty}
          />

          <Input
            type={'number'}
            value={data.cost}
            placeHolder={'Стоимость'}
            onChangeHandler={inputHandler}
            fieldName={'cost'}
            itemValidationResult={getValidationResult('cost')}
            onBlur={setDirty}
          />

          <div className={classes.CheckBoxes}>
            <CheckBox
              title={'Активен'}
              value={data.active}
              onChangeHandler={inputHandler}
              fieldName={'active'}
              type={'checkbox'}
            />
            <CheckBox
              title={'Можно бронировать место'}
              value={data.placeReserve}
              onChangeHandler={inputHandler}
              fieldName={'placeReserve'}
              type={'checkbox'}
            />
          </div>

          <div className={classes.ButtonsContainer}>
            <Button
              wide
              text={!edit ? 'Добавить абонемент' : 'Сохранить изменения'}
              onClick={createOrUpdateAction}
              disabled={disabled}
            />
            {edit && (
              <Button
                disabled={!checkAbility(Action.Delete, Subject.Subscriptions)}
                text="Удалить абонемент"
                red
                wide
                onClick={() => setInProgress(true)}
              />
            )}
            {!edit && (
              <Button
                wide
                light
                text={'Отмена'}
                onClick={() => setModalActive(false)}
              />
            )}
          </div>
        </div>
      </div>
    )
  return (
    <div className={classes.Container} onClick={(e) => e.stopPropagation()}>
      <div className={classes.InFormWrapper}>
        <img
          className={classes.ImageSure}
          src={are_you_sure}
          alt={'Подтверждение действия'}
        />
        <div className={classes.TitleSure}>Вы уверены? </div>
        <div className={classes.CommentSure}>
          При удалении абонемента последствия...
        </div>
        <div className={classes.ButtonsContainer}>
          <Button wide text={'Подтвердить'} onClick={deleteAction} />

          <Button
            wide
            light
            text={'Отменить'}
            onClick={() => setModalActive(false)}
          />
        </div>
      </div>
    </div>
  )
}
