import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import {
  BARRIER_LOGS_ROUTE,
  BLACK_LIST_ROUTE,
  LOCAL_DEVICE_ROUTE,
  MAIN_ROUTE,
  PARKING_ROUTE,
  PARTNERS_ROUTE,
  ROLES_ROUTE,
  SALES_LIST_ROUTE,
  SALES_ROUTE,
  SUBSCRIPTIONS_ROUTE,
  USERS_ROUTE,
  WHITE_LIST_ROUTE,
} from '../../../../constants'
import blue_cone from '../../../../assets/images/icons/blue_cone.svg'
import userCircle from '../../../../assets/images/icons/userCircle.svg'

export const usePageHeader = () => {
  const location = useLocation()
  const [part1, setPart1] = useState<string>('Text 1')
  const [part2, setPart2] = useState<string>('text 2')
  const [icon, setIcon] = useState<string>('')
  const [title, setTitle] = useState<string>('')

  useEffect(() => {
    switch (location.pathname) {
      case USERS_ROUTE:
        setPart1('')
        setPart2('Пользователи')
        setIcon(userCircle)
        setTitle('человек')
        break
      case LOCAL_DEVICE_ROUTE:
        setPart1('')
        setPart2('Устройства')
        setIcon(userCircle)
        setTitle('устройств')
        break
      case MAIN_ROUTE:
        setPart1('Наши')
        setPart2('Парковки')
        setIcon(blue_cone)
        setTitle('парковок')
        break
      case PARKING_ROUTE:
        setPart1('Управление')
        setPart2('Парковками')
        setIcon(blue_cone)
        setTitle('парковок')
        break
      case ROLES_ROUTE:
        setPart1('')
        setPart2('Роли и права')
        break
      case BARRIER_LOGS_ROUTE:
        setPart1('')
        setPart2('Журнал шлагбаумов')
        break
      case SALES_ROUTE:
        setPart1('')
        setPart2('Продажи')
        break
      case SUBSCRIPTIONS_ROUTE:
        setPart1('Список')
        setPart2('Абонементов')
        setTitle('абонементов')
        break

      case PARTNERS_ROUTE:
        setPart1('Список')
        setPart2('Партнеров')
        setTitle('партнеров')
        break
      case BLACK_LIST_ROUTE:
        setPart1('')
        setPart2('Черный список')
        setTitle('записей')
        break
      case WHITE_LIST_ROUTE:
        setPart1('')
        setPart2('Белый список')
        setTitle('записей')
        break
      case SALES_LIST_ROUTE:
        setPart1('')
        setPart2('Журнал продаж')
        setTitle('продаж')
        break

      default:
        break
    }
  }, [location])

  return { part1, part2, icon, title }
}
