import { useState } from 'react'
import { ParkingInterface } from '../../../../../../../common/interfaces/interfaces'


export const useSide1 = (parking: ParkingInterface) => {
  const [barrierIn] = useState(
    // barriersInitialValue.find(
    //   (el) => el.parkingId.toString() === parking.id && el.entry === true,
    // ),
  )
  const [barrierOut] = useState(
    // barriersInitialValue.find(
    //   (el) => el.parkingId.toString() === parking.id && el.entry === false,
    // ),
  )

  return { barrierIn, barrierOut }
}
