import { useCallback, useEffect, useState } from 'react'
import {
  LoginCredentials,
  NotificationTypes,
  UserInterface,
  ValidationResultInterface,
  ValidationRulesInterface,
} from '../../../common/interfaces/interfaces'
import { getApi } from '../../../api/api'
import { useNavigate } from 'react-router-dom'
import { loginValidations, MAIN_ROUTE } from '../../../constants'
import { useInput } from '../../../common/hooks/useInput'
import { useDispatch } from 'react-redux'
import { setCurrentUser } from '../../../store/slices/currentUserSlice'
import { useNotification } from '../../../common/hooks/useNotification'
import { v4 } from 'uuid'
import { setAuthToken } from '../../../store/slices/authSlice'
import { useValidation } from '../../../common/hooks/useValidation'
import { useLogout } from '../../../common/hooks/useLogout'
import { jwtDecode } from 'jwt-decode'

const validations: ValidationRulesInterface[] = loginValidations

const initialValue = {
  login: '',
  password: '',
}
export const useLogin = () => {
  const [api] = useState(() => getApi())
  const [data, setData] = useState<LoginCredentials>(initialValue)
  const navigate = useNavigate()
  const [validationResult, setValidationResult] = useState<
    ValidationResultInterface[] | []
  >([])
  const [disabled, setDisabled] = useState<boolean>(true)

  const { logOut } = useLogout()

  const dispatch = useDispatch()

  const { inputHandler } = useInput(setData)
  const { addNotificationAction } = useNotification()

  const { validate, checkAndAddValidation, setDirty } = useValidation(
    setValidationResult,
    validationResult,
    validations,
  )

  const loginAction = useCallback(async () => {
    try {
      const { login, ...rest } = data
      const result = await api.login({ login: login.toLowerCase(), ...rest })
      const payload: UserInterface = jwtDecode(result.token)

      localStorage.setItem('currentUser', JSON.stringify(payload))

      dispatch(setCurrentUser(payload))

      dispatch(setAuthToken(result.token))
      api.setToken(result.token)

      navigate(MAIN_ROUTE)
    } catch (error: any) {
      if (error.response.status === 401) logOut().then()
    }
  }, [data, api, navigate, addNotificationAction, dispatch, logOut])

  useEffect(() => {
    for (var item in data) {
      if (validations.map((el) => el.fieldName).includes(item)) {
        checkAndAddValidation(item)
      }
    }
  }, [checkAndAddValidation, data])

  useEffect(() => {
    for (var item in data) {
      if (validations.map((el) => el.fieldName).includes(item)) {
        validate(item, data[item])
      }
    }
  }, [data, logOut])

  useEffect(() => {
    setDisabled(validationResult.filter((el) => el.notValid).length > 0)
  }, [validationResult])

  const getValidationResult = (fieldName: string) => {
    return validationResult.find((el) => el.name === fieldName)
  }

  return {
    data,
    inputHandler,
    loginAction,
    getValidationResult,
    setDirty,
    disabled,
  }
}
