import React from 'react'
import classes from './UserMenu.module.sass'
import { Button } from '../../../../../button/Button'
import signOut from '../../../../../../../assets/images/icons/SignOut.svg'
import { useLogout } from '../../../../../../hooks/useLogout'

type Props = {
  toggle: () => void
}
export const UserMenu = ({ toggle }: Props) => {
  const { logOut } = useLogout()

  return (
    <div className={classes.Container}>
      <Button text="Выйти" red icon={signOut} onClick={logOut} />
    </div>
  )
}
