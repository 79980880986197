import React from 'react'
import { MenuLink } from '../../interfaces'
import classes from './MenuItem.module.sass'
import { MenuSubItem } from './components/menuSubItem/MenuSubItem'
import { useMenuItem } from './hooks/useMenuItem'
import menuArrow from '../../../../../assets/images/menuArrow.svg'
import { useCurrentUser } from '../../../../hooks/useCurrentUser'
import { LOCAL_DEVICE_ROUTE, PARTNERS_ROUTE } from '../../../../../constants'

type Props = {
  link: MenuLink
  toggleMenu: () => void
  menuActive: boolean
  toggle: (id: number) => void
  activeItem: number
}

export const MenuItem = ({
  link,
  toggleMenu,
  menuActive,
  activeItem,
  toggle,
}: Props) => {
  const { navigate, location, available, subitemsFilter } = useMenuItem(link)
  const { currentUser } = useCurrentUser()

  if (
    available &&
    (link.subLinks.filter((el) => !subitemsFilter.includes(el.subject)).length >
      0 ||
      link.subLinks.length === 0)
  )
    return (
      <div>
        <div
          className={
            location.pathname === link.link ? classes.LinkActive : classes.Link
          }
          onClick={() => {
            toggle(link.id)
            link.link && navigate(link.link)
            if (link.link && menuActive) toggleMenu()
          }}
        >
          {link.name}{' '}
          {location.pathname === link.link && (
            <img
              className={classes.MenuArrow}
              src={menuArrow}
              alt="Стрелка меню"
            />
          )}
        </div>
        {currentUser.super ? (
          <div>
            {activeItem === link.id &&
              link.subLinks.map((subLink) => (
                <MenuSubItem
                  key={subLink.id}
                  subLink={subLink}
                  toggleMenu={toggleMenu}
                  menuActive={menuActive}
                />
              ))}
          </div>
        ) : (
          <div>
            {activeItem === link.id &&
              link.subLinks
                .filter(
                  (el) =>
                    el.link !== PARTNERS_ROUTE &&
                    el.link !== LOCAL_DEVICE_ROUTE,
                )
                .map((subLink) => (
                  <MenuSubItem
                    key={subLink.id}
                    subLink={subLink}
                    toggleMenu={toggleMenu}
                    menuActive={menuActive}
                  />
                ))}
          </div>
        )}
      </div>
    )
  return <></>
}
