import React from 'react'
import classes from './BlackListItem.module.sass'
import { CarSign } from '../../../../../common/components/carSign/CarSign'
import { ListButton } from '../../../../../common/components/listButton/ListButton'
import {
  Action,
  ListItemInterface,
  Subject,
} from './../../../../../common/interfaces/interfaces'
import { Status } from '../../../../../common/components/status/Status'
import Check from '../../../../../assets/images/icons/Check.svg'
import { useBlackListItem } from './hooks/useBlackListItem'
import { Shorten } from '../../../../../common/components/shorten/Shorten'

type Props = {
  listItem: ListItemInterface
  startEdit: (listItem: ListItemInterface) => void
  toGroup: (el: ListItemInterface) => void
  inGroup: boolean
  getList?: (reUsed: boolean) => Promise<void>
}

export const BlackListItem = ({
  listItem,
  startEdit,
  toGroup,
  inGroup,
  getList,
}: Props) => {
  const { updateStatus, checkAbility } = useBlackListItem(listItem, getList)

  return (
    <div className={classes.Container}>
      <div className={classes.ContentWrapper}>
        <div
          onClick={() => toGroup(listItem)}
          className={
            !inGroup ? classes.UnCheckedContainer : classes.CheckedContainer
          }
        >
          {inGroup && <img src={Check} alt="Чек бокс" />}
        </div>

        <CarSign number={listItem.carNumber} />
        <div className={classes.Comment}>{listItem.comment}</div>

        <div>
          id парковки:{' '}
          <span className={classes.Id}>
            <Shorten id={listItem.parkingId} />
          </span>
        </div>
      </div>

      <div className={classes.StatusAndButtonsWrapper}>
        <div className={classes.Active}>
          <Status
            pointer
            toggle
            active_props={listItem.active}
            title={{ positive: 'Активна', negative: 'Не активна' }}
            fixedWith="130px"
            onClick={updateStatus}
          />
        </div>
        <div className={classes.VerticalLine} />
        <ListButton
          disabled={!checkAbility(Action.Update, Subject.BlackList)}
          title={'Редактировать'}
          type={'edit'}
          onClick={() => startEdit(listItem)}
        />
      </div>
    </div>
  )
}
