import { useDispatch } from 'react-redux'
import { NotificationInterface } from './../../../../interfaces/interfaces'
import { removeNotification } from '../../../../../store/slices/notificationSlice'
import { useEffect, useState } from 'react'

export const useNotificationItem = (el: NotificationInterface) => {
  const dispatch = useDispatch()
  const [notificationWidth, setNotificationWidth] = useState(270)
  const [intervalId, setIntervalId] = useState<string | number | undefined>(
    undefined,
  )
  const [exit, setExit] = useState<boolean>(false)

  const removeNotificationAction = () => {
    dispatch(removeNotification(el))
  }

  const handleStartTimer = () => {
    const id = window.setInterval(() => {
      setNotificationWidth((prev) => {
        if (prev > 0) {
          return prev - 5
        }
        clearInterval(id)
        return prev
      })
    }, 100)
    setIntervalId(id)
  }

  useEffect(() => {
    handleStartTimer()
  }, [])

  useEffect(() => {
    if (notificationWidth === 10) {
      handleExitNotification()
    }
    if (notificationWidth === 0) {
      handleCloseNotification()
    }
  }, [notificationWidth])

  const handleExitNotification = () => {
    setExit(true)
  }
  const handleCloseNotification = () => {
    dispatch(removeNotification(el))
  }

  return { removeNotificationAction, notificationWidth, exit }
}
