import React from 'react'
import classes from './PassOutContent.module.sass'
import { CarSign } from '../../../../../common/components/carSign/CarSign'
import signIn from '../../../../../assets/images/icons/SignIn.svg'
import signOut from '../../../../../assets/images/icons/SignOut.svg'
import { format } from 'date-fns'
import {
  BarrierPassInterface,
  PaymentTypes,
  SalesListItemInterface,
} from '../../../../../common/interfaces/interfaces'
import { AutoManual } from '../../../../../common/components/autoManual/AutoManual'
import { v4 } from 'uuid'
import { Video } from '../../../../../common/components/video/Video'

type Props = {
  barrierPassOut: BarrierPassInterface | undefined | null
  sale: SalesListItemInterface | undefined | null
}

export const PassOutContent = ({ barrierPassOut, sale }: Props) => {
  return (
    <div className={classes.Card}>
      <div className={classes.Value}>
        <span className={classes.Name}>ID парковки:</span>
        {barrierPassOut?.parkingId}
      </div>
      {sale?.paymentType === PaymentTypes.SUBSCRIPTION ? (
        <div className={classes.Value}>
          <span className={classes.Name}>Абонемент:</span>
          <div>{sale.subscriptionId}</div>
        </div>
      ) : sale?.cost ? (
        <div className={classes.Payment}>
          <span className={classes.Name}>Сумма оплаты</span>
          <div className={classes.Sum}>
            {sale?.cost} <span className={classes.Rur}>руб.</span>
          </div>
        </div>
      ) : (
        <div className={classes.Payment}>
          <span className={classes.Name}>Не оплачен</span>
          <div className={classes.Sum}></div>
        </div>
      )}
      {/* <div className={classes.ForBorder} /> */}
      {barrierPassOut?.carNumber && (
        <div className={classes.Value}>
          <span className={classes.Name}>
            Номер машины: <CarSign number={barrierPassOut?.carNumber} />{' '}
          </span>
        </div>
      )}
      <div className={classes.ForBorder} />

      <div className={classes.BarrierContainer}>
        <div className={classes.BarrierInfo}>
          <div className={classes.Name}>Шлагбаум:</div>
          <div className={classes.ValueOut}>
            <img src={signOut} />
            {barrierPassOut?.id}
          </div>
        </div>
        <div className={classes.TimeInfo}>
          <div className={classes.Name}>Выезд:</div>
          <div className={classes.DateTime}>
            <span className={classes.DateTitle}></span>{' '}
            <div className={classes.DateTimeWrapper}>
              {barrierPassOut?.dateTime &&
                format(new Date(barrierPassOut?.dateTime), 'dd.MM.yyyy')}
              <div className={classes.Time}>
                {barrierPassOut?.dateTime &&
                  format(new Date(barrierPassOut?.dateTime), 'HH:mm')}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.ForBorder} />
      {sale?.userId &&  <div className={classes.Value}>
          <span className={classes.Name}>Пользователь:</span>
          <div>{sale.userId}</div>
        </div>}
      <AutoManual auto={barrierPassOut?.userId ? false : true} />


      { barrierPassOut &&  <Video barrierLog={barrierPassOut} onPage />}
    </div>
  )
}
