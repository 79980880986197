import React from 'react'
import classes from './FilterWithGroupSelection.module.sass'
import { useFilterWithGroupSelection } from './hooks/useFilterWithGroupSelection'
import caretDownGrey from '../../../assets/images/icons/caretDownGrey.svg'
import Check from '../../../assets/images/icons/Check.svg'
import { FilterWithGroupSelectionItem } from './components/filterItem/FilterWithGroupSelectionItem'
import {
  ParkingInterface,
  PartnerInterface,
  ValidationResultInterface,
} from '../../interfaces/interfaces'
import x from '../../../assets/images/icons/X.svg'

type Props = {
  initialValue: PartnerInterface[] | ParkingInterface[]
  allText: string
  emptyText: string
  width100?:boolean
  label?: string
  inputHandler: (
    value: (string | undefined)[],
    fieldName: string,
    type: string,
  ) => void
  fieldName: string
  selected: (string | undefined)[]
  itemValidationResult?: ValidationResultInterface
  onBlur?: (fieldName: string) => void
}

export const FilterWithGroupSelection = ({
  initialValue,
  allText,
  width100,
  label,
  inputHandler,
  fieldName,
  selected,
  itemValidationResult,
  onBlur,
  emptyText
}: Props) => {
  const {
    expanded,
    toggleFilter,
    list,
    resetItems,
    toggleItem,
    selectedItems,
  } = useFilterWithGroupSelection(
    initialValue,
    inputHandler,
    fieldName,
    selected,
  )


  

  return (
    <div className={width100 ? classes.Container100 : classes.Container}>
      {label && <div className={classes.Label}>{label}</div>}
      <div
        className={
          width100 && itemValidationResult?.notValid
            ? classes.SelectContainerRequired100
            : width100
            ? classes.SelectContainer100
            : itemValidationResult?.notValid
            ? classes.SelectContainerRequired
            : classes.SelectContainer
        }
        onClick={toggleFilter}
      >
        <div className={classes.FilterPlaceHolder}>
          {list.length === 0
            ? 'Нет элементов'
            : selectedItems.length === list.length
            ? allText
            : selectedItems.length === 0
            ? emptyText
            : selectedItems.map((el) => el.name).toString()}
        </div>
        <img
          className={!expanded ? classes.Caret : classes.CaretUp}
          src={caretDownGrey}
          alt='Развернуть/Свернуть'
        />
      </div>
      <div className={classes.ValidationErrorField}>
        {itemValidationResult?.isDirty &&
          itemValidationResult.notValid &&
          itemValidationResult?.errorMessage}
      </div>
      {expanded && (
        <div className={classes.ExpandedList}>
          <img
            src={x}
            className={classes.CloseIcon}
            onClick={() => {
              onBlur && onBlur(fieldName)
              toggleFilter()
            }}
            alt="Закрыть список"
          />
          <div className={classes.AllContainer} onClick={resetItems}>
            <div
              className={
                selectedItems.length === list.length
                  ? classes.CheckedContainer
                  : classes.UnCheckedContainer
              }
            >
              {selectedItems.length === list.length && <img src={Check} alt='Чекбокс выбора'/>}
            </div>
            <div>{allText}</div>
          </div>

          {list.map((el) => (
            <FilterWithGroupSelectionItem
              key={el.id}
              el={el}
              checked={selectedItems.map((el) => el.id).includes(el.id)}
              toggleItem={toggleItem}
            />
          ))}
        </div>
      )}
    </div>
  )
}
