import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UserInterface } from '../../common/interfaces/interfaces'
import { RootState } from '../store'

const initialState: { user: UserInterface } = {
  user: {
    id: '',
    login: '',
    fio: '',
    active: false,
    roleId: '',
    email: '',
    super: false,
    partnerId: '',
    parkingList: [],
  },
}

export const currentUserSlice = createSlice({
  name: 'current-user',
  initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<UserInterface>) => {
      state.user = { ...action.payload }
    },
  },
})

export const { setCurrentUser } = currentUserSlice.actions
export const selectCurrentUser = (state: RootState) => state.currentUser.user

export default currentUserSlice.reducer
