import React from "react";
import classes from "./CheckBox.module.sass";

type Props = {
    title?: string
    onChangeHandler: (value: string | boolean ,fieldName: string, type: string )=>void 
    value?: boolean
    fieldName:string
    type: string
}

export const CheckBox = ({title, value, onChangeHandler, fieldName,type} :Props) => {
  return (
    <div className={classes.Container}>
      <input className = {classes.CheckBox} type={type} checked = {value}   onChange={(e) => onChangeHandler(e.target.value, fieldName, type)}/>
      {title && <label>{title}</label>}
    </div>
  );
};
