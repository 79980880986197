import { useCallback, useEffect, useState } from 'react'
import { BarrierPassInterface } from '../../../common/interfaces/interfaces'
import { getApi } from '../../../api/api'
import { useLogout } from '../../../common/hooks/useLogout'
import { useCurrentUser } from '../../../common/hooks/useCurrentUser'
import { gteBackTime } from '../../../constants'

export const useBarrierPasses = () => {
  const [modalActive, setModalActive] = useState(false)
  const [barrierLog, setBarrierLog] = useState<BarrierPassInterface>()
  const [list, setList] = useState<BarrierPassInterface[]>([])
  const [api] = useState(() => getApi())
  const { logOut } = useLogout()
  const { currentUser } = useCurrentUser()
  const [gte, setGte] = useState<string>(
    new Date(new Date().getTime() - gteBackTime).toISOString(),
  )
  const [lte, setLte] = useState<string>(
    new Date(new Date().getTime()).toISOString(),
  )
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [disabled, setDisabled] = useState<boolean>(false)
  const [dateMessage] = useState<string>('Проверьте даты')

  // Pagination ...
  const [offset, setOffset] = useState(0)
  const [limit] = useState(8)
  const [totalCount, setTotalCount] = useState<number>(0)

  const getList = useCallback(
    async (reUsed?: boolean) => {
      try {
        if (!reUsed) {
          setList([])
          setLoading(true)
          await new Promise<void>((resolve) => setTimeout(() => resolve(), 700))
        }
        const result = await api.getBarrierPassesList({
          _start: offset.toString(),
          _end: (offset + limit).toString(),
          _sort: '_id',
          _order: 'desc',
          _gte: gte,
          _lte: lte,
          partnerId: currentUser.partnerId,
        })
        setModalActive(false)
        setList(result.data)
        setTotalCount(result.total)
        if (!reUsed) {
          setLoading(false)
          setMessage('Записей нет')
        }
      } catch (error: any) {
        if (error.response.status === 401) logOut().then()
        if (!reUsed) {
          setLoading(false)
          setMessage('Произошла ошибка во время запроса, попробуйте позже')
        }
      }
    },
    [api, logOut, currentUser.partnerId, gte, lte, limit, offset],
  )

  const setFrom = (value: string) => {
    setGte(new Date(value).toISOString())
  }
  const setTo = (value: string) => {
    setLte(new Date(new Date(value).setUTCHours(23, 59, 59, 999)).toISOString())
  }

  useEffect(() => {
    setDisabled(new Date(gte).getTime() > new Date(lte).getTime())
  }, [lte, gte])

  useEffect(() => {
    getList()
  }, [api, limit, offset])

  return {
    modalActive,
    setModalActive,
    barrierLog,
    setBarrierLog,
    list,
    gte,
    lte,
    setFrom,
    setTo,
    getList,
    loading,
    message,
    limit,
    offset,
    setOffset,
    totalCount,
    disabled,
    dateMessage,
  }
}
