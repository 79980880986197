import React from 'react'
import classes from './UserInfo.module.sass'
import { useUserInfo } from './hooks/useUserInfo'
import { UserMenu } from './components/userMenu/UserMenu'

export const UserInfo = () => {
  const { expanded, toggle, currentUser } = useUserInfo()

  return (
    <div className={classes.Container} onClick={toggle}>
      <div className={classes.AvatarImage}>
        {currentUser.login.slice(0, 1).toUpperCase()}
      </div>
      <div className={classes.InfoBlock}>
        <div className={classes.UserFio}>{currentUser.fio}</div>
        <div className={classes.Role}>
          {currentUser.super ? 'Супер пользователь' : 'Пользователь'}
        </div>
        {expanded && <UserMenu toggle={toggle} />}
      </div>
    </div>
  )
}
