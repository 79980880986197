import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { AuthState } from '../../common/interfaces/interfaces'

const initialState: AuthState = {
  token: '',
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload
    },
  },
})

export const { setAuthToken } = authSlice.actions
export const selectAuthToken = (state: RootState) => state.auth.token

export default authSlice.reducer
