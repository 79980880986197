import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAbility } from '../../../../../hooks/useAbility'
import { Action, Subject } from '../../../../../interfaces/interfaces'
import { MenuLink } from '../../../interfaces'

export const useMenuItem = (link: MenuLink) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { checkAbility } = useAbility()
  const [subitemsFilter, setSubitemsFilter] = useState<Subject[]>([])
  const available =
    link.subject !== Subject.Skip
      ? checkAbility(Action.Read, link.subject)
      : true

  const filterSubitems = () => {
    const arr = []
    for (const subLink of link.subLinks) {
      if (subLink.subject !== Subject.Skip)
        checkAbility(Action.Read, subLink.subject) === false &&
          arr.push(subLink.subject)
    }
    setSubitemsFilter(arr)
  }

  useEffect(() => {
    filterSubitems()
  }, [location.pathname])

  return { navigate, location, available, subitemsFilter }
}
