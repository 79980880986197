import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  ParkingInterface,
  ParkingList,
} from '../../common/interfaces/interfaces'
import { RootState } from '../store'

const initialState: ParkingList = {
  data: [],
}

export const parkingListSlice = createSlice({
  name: 'parking-list',
  initialState,
  reducers: {
    setParkingList: (state, action: PayloadAction<ParkingInterface[]>) => {
      state.data = action.payload
    },
  },
})

export const { setParkingList } = parkingListSlice.actions
export const selectParkingList = (state: RootState) => state.parkingList.data

export default parkingListSlice.reducer
