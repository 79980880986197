import React from 'react'
import classes from './CounterBookmark.module.sass'
import { CounterBookMarkItem } from './components/counterBookMarkItem/CounterBookMarkItem'

type Props = {
  allLength: number
  activeLength: number
  active: boolean
  toggleActive: ()=>void
}

export const CounterBookmark = ({ allLength, activeLength, active, toggleActive }: Props) => {

  return (
    <div className={classes.Container}>
      <CounterBookMarkItem
        active={!active}
        title={'Все'}
        quantity={allLength}
        toggleActive={toggleActive}
      />
      <CounterBookMarkItem
        active={active}
        title={'Активные'}
        quantity={activeLength}
        toggleActive={toggleActive}
      />
    </div>
  )
}
