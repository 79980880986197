import React from 'react'
import './App.css'
import { Route, Routes } from 'react-router-dom'
import { Protected } from './common/components/Protected'
import { Users } from './pages/users/Users'
import { Sales } from './pages/reports/sales/Sales'
import { Settings } from './pages/settings/Settings'
import { WhiteList } from './pages/lists/white_list/WhiteList'
import { BlackList } from './pages/lists/black_list/BlackList'
import {
  ADV_ROUTE,
  BARRIER_LOGS_ROUTE,
  BARRIER_PASS_ROUTE,
  BLACK_LIST_ROUTE,
  LOCAL_DEVICE_ROUTE,
  LOGIN_ROUTE,
  MAIN_ROUTE,
  PARKING_ROUTE,
  PARTNERS_ROUTE,
  PAYMENT_ITEM_ROUTE,
  ROLES_ROUTE,
  SALES_LIST_ROUTE,
  SALES_ROUTE,
  SETTINGS_ROUTE,
  SUBSCRIPTIONS_ROUTE,
  SUBSCRIPTION_ITEM_ROUTE,
  USERS_ROUTE,
  WHITE_LIST_ROUTE,
} from './constants'
import { Roles } from './pages/roles/Roles'
import { Login } from './pages/login/Login'
import { DashBoard } from './pages/dashBoard/DashBoard'
import { Parking } from './pages/parking/Parking'
import { Adv } from './pages/adv/Adv'
import { Partners } from './pages/partners/Partners'
import { SalesList } from './pages/salesList/SalesList'
import { Payment } from './pages/items/payment/Payment'
import { BarrierPass } from './pages/items/barrierPass/BarrierPass'
import { Subscriptions } from './pages/subscriptions/Subscriptions'
import { Subscription } from './pages/items/subscription/Subscription'
import { BarrierPasses } from './pages/barrier_passes/BarrierPasses'
import { LocalDevice } from './pages/local_device/LocalDevice'

function App() {
  return (
    <Routes>
      <Route path={LOGIN_ROUTE} element={<Login />} />
      <Route path={ADV_ROUTE} element={<Adv />} />
      <Route element={<Protected />}>
        <Route path={MAIN_ROUTE} element={<DashBoard />} />
        <Route path={USERS_ROUTE} element={<Users />} />
        <Route path={ROLES_ROUTE} element={<Roles />} />
        <Route path={SALES_ROUTE} element={<Sales />} />
        <Route path={BARRIER_LOGS_ROUTE} element={<BarrierPasses />} />
        <Route path={PARKING_ROUTE} element={<Parking />} />
        <Route path={SUBSCRIPTIONS_ROUTE} element={<Subscriptions />} />
        <Route path={SETTINGS_ROUTE} element={<Settings />} />
        <Route path={WHITE_LIST_ROUTE} element={<WhiteList />} />
        <Route path={BLACK_LIST_ROUTE} element={<BlackList />} />
        <Route path={PARTNERS_ROUTE} element={<Partners />} />
        <Route path={SALES_LIST_ROUTE} element={<SalesList />} />
        <Route path={LOCAL_DEVICE_ROUTE} element={<LocalDevice />} />
        <Route
          path={`${SUBSCRIPTION_ITEM_ROUTE}/:id`}
          element={<Subscription />}
        />
        <Route path={`${PAYMENT_ITEM_ROUTE}/:id`} element={<Payment />} />
        <Route path={`${BARRIER_PASS_ROUTE}/:id`} element={<BarrierPass />} />
      </Route>
    </Routes>
  )
}

export default App
