import React from 'react'
import classes from './Form.module.sass'
import UserCircleEdit from '../../../../assets/images/icons/UserCircleEdit.svg'
import UserCircleAdd from '../../../../assets/images/icons/UserCircleAdd.svg'
import { Button } from '../../../../common/components/button/Button'
import { useForm } from './hooks/useForm'
import {
  CloseSaleInterface,
  PaymentTypes,
  SalesListItemInterface,
} from '../../../../common/interfaces/interfaces'
import { Select } from '../../../../common/components/select/Select'
import { Input } from '../../../../common/components/input/Input'

type Props = {
  edit?: boolean
  setModalActive: React.Dispatch<React.SetStateAction<boolean>>
  initialValue: CloseSaleInterface
  getList: () => void
  current: SalesListItemInterface
}

export const Form = ({
  edit,
  setModalActive,
  initialValue,
  getList,
  current,
}: Props) => {
  const {
    inputHandler,
    data,
    getValidationResult,
    disabled,
    getSubscriptionsList,
    setDirty,
    closeSale,
  } = useForm(initialValue, getList, current)

  return (
    <div className={classes.Container} onClick={(e) => e.stopPropagation()}>
      <div className={classes.InFormWrapper}>
        <img
          className={classes.UserImage}
          src={edit ? UserCircleEdit : UserCircleAdd}
          alt="Изображение формы"
        />
        <div className={classes.Title}>Завершение стоянки</div>

        <Select
          label="Тип оплаты"
          options={[
            {
              id: '0',
              name: 'type',
              hidden: true,
              label: 'Тип оплаты',
              selected: true,
            },
            {
              id: '1',
              name: 'type',
              label: 'Наличные',
              value: PaymentTypes.CASH.toString(),
            },
            {
              id: '2',
              name: 'type',
              label: 'Карта',
              value: PaymentTypes.CARD.toString(),
            },
            {
              id: '3',
              name: 'type',
              label: 'Абонемент',
              value: PaymentTypes.SUBSCRIPTION.toString(),
            },
          ]}
          value={data.paymentType?.toString()}
          onChangeHandler={inputHandler}
          fieldName={'paymentType'}
          type={'select'}
          itemValidationResult={getValidationResult('paymentType')}
          onBlur={setDirty}
        />

        {data.paymentType === PaymentTypes.SUBSCRIPTION.toString() && (
          <Select
            disabled={getSubscriptionsList().length === 0}
            label="Абонемент"
            options={[
              {
                id: '0',
                name: 'subscriptionId',
                hidden: true,
                label: 'Абонемент',
                selected: true,
              },
              ...getSubscriptionsList(),
            ]}
            value={data.subscriptionId}
            onChangeHandler={inputHandler}
            fieldName={'subscriptionId'}
            type={'select'}
            itemValidationResult={getValidationResult('subscriptionId')}
            onBlur={setDirty}
          />
        )}

        <div className={classes.ButtonsContainer}>
          <Button
            wide
            text={'Завершить стоянку'}
            onClick={closeSale}
            disabled={disabled}
          />

          <Button
            wide
            light
            text={'Отмена'}
            onClick={() => setModalActive(false)}
          />
        </div>
      </div>
    </div>
  )
}
