import { useEffect, useState } from 'react'
import { getApi } from '../../../api/api'
import { useDispatch } from 'react-redux'
import { setParkingList } from '../../../store/slices/parkingListSlice'
import { useLocation } from 'react-router-dom'
import { setPartnerList } from '../../../store/slices/partnerListSlice'

import { useCurrentUser } from '../../../common/hooks/useCurrentUser'
import { useLogout } from '../../../common/hooks/useLogout'

export const useAppWrapper = () => {
  const [menuActive, setMenuActive] = useState<boolean>(false)
  const [api] = useState(getApi())
  const dispatch = useDispatch()
  const toggleMenu = () => setMenuActive((prev) => !prev)
  const location = useLocation()
  const { currentUser } = useCurrentUser()
  const { logOut } = useLogout()

  useEffect(() => {
    ;(async () => {
      try {
        const result = await api.getParkingList({
          _start: '0',
          _end: '100',
          _sort: 'id',
          _order: 'desc',
          isActive: '',
        })
        dispatch(setParkingList(result.data))

        if (currentUser.super) {
          const result = await api.getPartnerList({
            _start: '0',
            _end: '100',
            _sort: 'id',
            _order: 'desc',
            isActive: '',
          })
          dispatch(setPartnerList(result.data))
        }
      } catch (error: any) {
        if (error.response.status === 401) logOut().then()
        console.log(error)
      }
    })()
  }, [location.pathname, currentUser])

  return { menuActive, toggleMenu }
}
