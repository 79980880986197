import React from 'react'
import classes from './BarrierPasses.module.sass'
import { PageHeader } from '../../common/components/pageHeader/PageHeader'
import { Filter } from '../../common/components/filter/Filter'
import { Modal } from '../../common/components/modal/Modal'
import { Video } from '../../common/components/video/Video'
import { BarrierPassItem } from './components/barrierPassItem/BarrierPassItem'
import { useBarrierPasses } from './hooks/useBarrierPasses'
import { ListLoader } from '../../common/components/listLoader/ListLoader'
import { Pagination } from '../../common/components/pagination/Pagination'

export const BarrierPasses = () => {
  const {
    modalActive,
    setModalActive,
    barrierLog,
    setBarrierLog,
    list,
    gte,
    lte,
    setFrom,
    setTo,
    getList,
    loading,
    message,
    limit,
    offset,
    setOffset,
    totalCount,
    disabled,
    dateMessage
  } = useBarrierPasses()

  return (
    <div className={classes.Container}>
      <div className={classes.HeaderAndButtonWrapper}>
        <PageHeader />
      </div>
      <Filter
        gte={gte}
        lte={lte}
        setFrom={setFrom}
        setTo={setTo}
        getReport={getList}
        disabled = {disabled}
        dateMessage = {dateMessage}
      />
      <div className={classes.ListContainer}>
        {list.length > 0 ? (
          list.map((log) => (
            <BarrierPassItem
              key={log.id}
              log={log}
              setBarrierLog={setBarrierLog}
              setModalActive={setModalActive}
            />
          ))
        ) : !loading ? (
          <div className={classes.LoaderContainer}>
            <span className={classes.Message}>{message}</span>
          </div>
        ) : (
          <ListLoader />
        )}
      </div>

      <Pagination
        totalCount={totalCount}
        limit={limit}
        offset={offset}
        setOffset={setOffset}
      />

      <Modal central modalActive={modalActive} setModalActive={setModalActive}>
        <Video barrierLog={barrierLog} />
      </Modal>
    </div>
  )
}
