import React from "react";
import classes from "./Adv.module.sass";
import { Bar } from "../../common/components/bar/Bar";

export const Adv = () => {
  return (
    <div className={classes.Container}>
      <Bar />
      <div className={classes.Title}>
        Реклама <span className={classes.TitleBold}>наших парковок</span>
      </div>
      <div className={classes.ImageContainer}/>
    </div>
  );
};
