import { useCallback, useEffect, useState } from 'react'
import {
  Action,
  FormRoleAbility,
  FormRoleInterface,
  FormRoleSubject,
  OptionItemInterface,
  RoleInterface,
  Subject,
  ValidationResultInterface,
  ValidationRulesInterface,
} from '../../../../../common/interfaces/interfaces'
import { getApi } from '../../../../../api/api'
import { useLogout } from '../../../../../common/hooks/useLogout'
import { useSelector } from 'react-redux'
import { useCurrentUser } from '../../../../../common/hooks/useCurrentUser'
import { selectPartnerList } from '../../../../../store/slices/partnerListSlice'
import { useValidation } from '../../../../../common/hooks/useValidation'
import { useInput } from '../../../../../common/hooks/useInput'
import { roleValidations } from '../../../../../constants'
import { useAbility } from '../../../../../common/hooks/useAbility'

const validations: ValidationRulesInterface[] = roleValidations

export const useForm = (
  initialValue: FormRoleInterface,
  getList: (reUsed?: boolean) => void,
) => {
  const [inProgress, setInProgress] = useState<boolean>(false)
  const [data, setData] = useState<FormRoleInterface>(initialValue)

  const {checkAbility} = useAbility()

  const [api] = useState(() => getApi())
  const { logOut } = useLogout()
  const { currentUser } = useCurrentUser()

  const [validationResult, setValidationResult] = useState<
    ValidationResultInterface[] | []
  >([])
  const [disabled, setDisabled] = useState<boolean>(true)
  const partnerList = useSelector(selectPartnerList)

  useEffect(() => {
    if (!currentUser.super && data.partnerId === '')
      setData((prevState) => ({
        ...prevState,
        partnerId: currentUser.partnerId,
      }))
  }, [currentUser])

  const getPartnerList = () => {
    let arr: OptionItemInterface[] = []
    for (const item of partnerList) {
      let pattern: OptionItemInterface = {
        id: item.id,
        label: item.name,
        name: item.name,
        value: item.id,
      }
      arr.push(pattern)
    }
    return arr
  }

  const { validate, checkAndAddValidation, setDirty } = useValidation(
    setValidationResult,
    validationResult,
    validations,
  )

  const { inputHandler } = useInput(setData, checkAndAddValidation)

  useEffect(() => {
    for (var item in data) {
      if (validations.map((el) => el.fieldName).includes(item)) {
        checkAndAddValidation(item)
      }
    }
  }, [checkAndAddValidation, data])

  useEffect(() => {
    for (var item in data) {
      if (validations.map((el) => el.fieldName).includes(item)) {
        validate(item, data[item])
      }
    }
  }, [data])

  useEffect(() => {
    setDisabled(validationResult.filter((el) => el.notValid).length > 0)
  }, [validationResult])

  const createOrUpdateAction = useCallback(async () => {
    try {
      // From form data to data
      const abilities: FormRoleAbility[] = [...data.abilities]
      const arr = []
      for (const el of abilities) {
        arr.push({
          action: el.value,
          subjects: el.subjects.filter((el) => el.can).map((el) => el.value),
        })
      }
      const aggregatedData: RoleInterface = {
        ...data,
        abilities: [...arr].filter((el) => el.subjects.length > 0),
      }
      if (data.id !== '') {
        await api.updateRole(aggregatedData)
      } else {
        const { id, ...rest } = aggregatedData
        await api.createRole({ ...rest })
      }
      await getList(true)
    } catch (error: any) {
      if (error.response.status === 401) logOut().then()
    }
  }, [api, data, getList, logOut])

  const deleteAction = useCallback(async () => {
    try {
      initialValue.id && (await api.deleteRole({ id: initialValue.id }))
      await getList(true)
    } catch (error: any) {
      if (error.response.status === 401) logOut().then()
    }
  }, [api, getList, initialValue.id, logOut])

  const getValidationResult = (fieldName: string) => {
    return validationResult.find((el) => el.name === fieldName)
  }

  const abilitiesHandler = (action: Action, subject: Subject) => {
    abilitiesSubHandler(action, subject)
    if (action === Action.Read) {
      abilitiesSubHandler(Action.Delete, subject, true)
      abilitiesSubHandler(Action.Create, subject, true)
      abilitiesSubHandler(Action.Update, subject, true)
    }
  }

  const abilitiesSubHandler = (
    action: Action,
    subject: Subject,
    reset?: boolean,
  ) => {
    let handleData = { ...data }

    let abilityForEdit = handleData.abilities.find(
      (el: FormRoleAbility) => el.value === action,
    )
    let subjectForEdit = abilityForEdit?.subjects.find(
      (el: FormRoleSubject) => el.value === subject,
    )

    if (subjectForEdit) {
      subjectForEdit.can = reset
        ? false
        : !handleData.abilities
            .find((el: FormRoleAbility) => el.value === action)
            ?.subjects.find((el: FormRoleSubject) => el.value === subject)?.can
    }

    handleData = {
      ...handleData,
      abilities: handleData.abilities.filter(
        (el: FormRoleAbility) => el.value !== action,
      ),
    }

    abilityForEdit = abilityForEdit &&
      subjectForEdit && {
        ...abilityForEdit,
        subjects: [
          ...abilityForEdit.subjects.filter(
            (el: FormRoleSubject) => el.value !== subject,
          ),
          subjectForEdit,
        ],
      }

    abilityForEdit &&
      setData({
        ...handleData,
        abilities: [...handleData.abilities, abilityForEdit],
      })
  }

  const getAbilitySubjectValue = (action: string, subject: string) => {
    const abilityItem = data.abilities.find((el) => el.value === action)

    if (abilityItem !== undefined) {
      const subjectItem = abilityItem.subjects.find(
        (el) => el.value === subject,
      )
      if (subjectItem !== undefined) return subjectItem.can
    }
    return false
  }

  const checkDisabled = (action: Action, subject: Subject) => {
    if (action === Action.Read) return false
    if ((action === Action.Create || action === Action.Update  || action === Action.Delete) && subject === Subject.BarrierPasses)  return true
    if ((action === Action.Create || action === Action.Update  || action === Action.Delete) && subject === Subject.Sales)  return true
    if ((action === Action.Create || action === Action.Update  || action === Action.Delete) && subject === Subject.SalesReport)  return true

    const result = data.abilities
      .find((el) => el.value === Action.Read)
      ?.subjects.find((el) => el.value === subject)?.can
    return !result
  }

  return {
    inProgress,
    setInProgress,
    inputHandler,
    data,
    createOrUpdateAction,
    deleteAction,
    getValidationResult,
    disabled,
    setDirty,
    currentUser,
    getPartnerList,
    abilitiesHandler,
    getAbilitySubjectValue,
    checkDisabled,
    checkAbility
  }
}
