import React from 'react'
import classes from './Form.module.sass'
import { Input } from '../../../../common/components/input/Input'
import { Button } from '../../../../common/components/button/Button'
import { useForm } from './hooks/useForm'
import are_you_sure from '../../../../assets/images/icons/are_you_sure.svg'
import {
  Action,
  FormRoleAbility,
  FormRoleInterface,
  FormRoleSubject,
  RoleInterface,
  Subject,
} from '../../../../common/interfaces/interfaces'
import newRole from '../../../../assets/images/newRole.svg'
import editRole from '../../../../assets/images/editRole.svg'

import { Toggle } from '../../../../common/components/toggle/Toggle'
import { Select } from '../../../../common/components/select/Select'
import { subjectsPattern } from '../../../../constants'

type Props = {
  edit?: boolean
  setModalActive: React.Dispatch<React.SetStateAction<boolean>>
  initialValue: FormRoleInterface
  getList: () => void
}

export const Form = ({
  edit,
  setModalActive,
  initialValue,
  getList,
}: Props) => {
  const {
    inProgress,
    setInProgress,
    inputHandler,
    data,
    createOrUpdateAction,
    deleteAction,
    getValidationResult,
    disabled,
    setDirty,
    currentUser,
    getPartnerList,
    abilitiesHandler,
    getAbilitySubjectValue,
    checkDisabled,
    checkAbility,
  } = useForm(initialValue, getList)

  function sort(
    a: FormRoleAbility | FormRoleSubject,
    b: FormRoleAbility | FormRoleSubject,
  ) {
    if (a.name && b.name && a.name < b.name) {
      return -1
    } else if (a > b) {
      return 1
    }
    return 0
  }

  if (!inProgress)
    return (
      <div className={classes.Container} onClick={(e) => e.stopPropagation()}>
        <div className={classes.InFormWrapper}>
          <img
            className={classes.UserImage}
            src={!edit ? newRole : editRole}
            alt="Изображение формы"
          />
          <div className={classes.Title}>
            {!edit ? 'Новая роль' : 'Редактирование роли'}
          </div>

          <div className={classes.InputContainer}>
            <Input
              type={'text'}
              value={data.name}
              placeHolder={'Название роли'}
              onChangeHandler={inputHandler}
              fieldName={'name'}
              itemValidationResult={getValidationResult('name')}
              onBlur={setDirty}
            />
          </div>

          {currentUser.super && (
            <div className={classes.InputContainer}>
              <Select
                options={[
                  {
                    id: '0',
                    name: 'type',
                    hidden: true,
                    label:
                      getPartnerList.length > 0
                        ? 'Выберите партнера'
                        : 'Нет партнеров',
                    selected: true,
                  },
                  ...getPartnerList(),
                ]}
                value={data.partnerId}
                onChangeHandler={inputHandler}
                fieldName={'partnerId'}
                type={'select'}
                itemValidationResult={getValidationResult('partnerId')}
                onBlur={setDirty}
              />
            </div>
          )}

          {/* mobile */}
          <div className={classes.SubjectLabelsMobile}>
          {subjectsPattern.sort(sort).map((el: FormRoleSubject) => (
            <div>
              <div className={classes.SubjectItem}>{el.name}</div>
              <div className={classes.RoleCheckBoxesContainerMobile}>
                {data.abilities.sort(sort).map((action: FormRoleAbility) => (
                  <div className={classes.ActionItem}>
                    <div>{action.name}</div>
                    {action.subjects.filter(subject =>subject.value === el.value)
                      .sort(sort)
                      .map((subject: FormRoleSubject) => (
                        <div className={classes.RoleToggleContainer}>
                          <Toggle
                            disabled={checkDisabled(
                              action.value,
                              subject.value,
                            )}
                            action={action.value}
                            subject={subject.value}
                            on_props={getAbilitySubjectValue(
                              action.value,
                              subject.value,
                            )}
                            onChangeHandler={abilitiesHandler}
                          />
                        </div>
                      ))}
                  </div>
                ))}
              </div>
            </div>
          ))}
          </div>

          {/* mobile */}

          <div className={classes.SelectorContainer}>
            <div className={classes.SubjectLabels}>
              {subjectsPattern.sort(sort).map((el: FormRoleSubject) => (
                <div className={classes.SubjectItem}>{el.name}</div>
              ))}
            </div>

            <div className={classes.RoleCheckBoxesContainer}>
              {data.abilities.sort(sort).map((action: FormRoleAbility) => (
                <div className={classes.ActionItem}>
                  <div>{action.name}</div>
                  {action.subjects
                    .sort(sort)
                    .map((subject: FormRoleSubject) => (
                      <div className={classes.RoleToggleContainer}>
                        <Toggle
                          disabled={checkDisabled(action.value, subject.value)}
                          action={action.value}
                          subject={subject.value}
                          on_props={getAbilitySubjectValue(
                            action.value,
                            subject.value,
                          )}
                          onChangeHandler={abilitiesHandler}
                        />
                      </div>
                    ))}
                </div>
              ))}
            </div>
          </div>

          <div className={classes.ButtonsContainer}>
            <Button
              wide
              text={!edit ? 'Добавить роль' : 'Сохранить изменения'}
              onClick={createOrUpdateAction}
              disabled={disabled}
            />
            {edit && (
              <Button
                disabled={!checkAbility(Action.Delete, Subject.Roles)}
                text="Удалить роль"
                red
                wide
                onClick={() => setInProgress(true)}
              />
            )}
            {!edit && (
              <Button
                wide
                light
                text={'Отмена'}
                onClick={() => setModalActive(false)}
              />
            )}
          </div>
        </div>
      </div>
    )
  return (
    <div className={classes.Container} onClick={(e) => e.stopPropagation()}>
      <div className={classes.InFormWrapper}>
        <img
          className={classes.ImageSure}
          src={are_you_sure}
          alt={'Подтверждение действия'}
        />
        <div className={classes.TitleSure}>Вы уверены? </div>
        <div className={classes.CommentSure}>
          При удалении роли последствия...
        </div>
        <div className={classes.ButtonsContainer}>
          <Button wide text={'Подтвердить'} onClick={deleteAction} />

          <Button
            wide
            light
            text={'Отменить'}
            onClick={() => setModalActive(false)}
          />
        </div>
      </div>
    </div>
  )
}
