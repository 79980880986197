import React from 'react'
import { PageHeader } from '../../common/components/pageHeader/PageHeader'
import classes from './Parking.module.sass'
import { useParking } from './hooks/useParking'
import PlusCircle from '../../assets/images/icons/PlusCircle.svg'
import { Button } from '../../common/components/button/Button'
import { ParkingItem } from './components/parkingItem/ParkingItem'
import { Modal } from '../../common/components/modal/Modal'
import { Form } from './components/form/Form'
import { CounterBookmark } from '../../common/components/counterBookmark/CounterBookmark'
import { ListLoader } from '../../common/components/listLoader/ListLoader'
import { Pagination } from '../../common/components/pagination/Pagination'
import { Action, Subject } from '../../common/interfaces/interfaces'

export const Parking = () => {
  const {
    list,
    modalActive,
    setModalActive,
    edit,
    setEdit,
    initialValue,
    startEdit,
    startCreate,
    getList,
    loading,
    message,
    toggleActive,
    totalCount,
    limit,
    offset,
    setOffset,
    active,
    activeCount,
    checkAbility,
    checkDeviceActive,
    getPartnerName
  } = useParking()

  return (
    <div className={classes.Container}>
      <div className={classes.HeaderAndButtonWrapper}>
        <PageHeader length={totalCount} />
        <div className={classes.BookmarksAndAdd}>
          <CounterBookmark
            active={active}
            allLength={totalCount}
            activeLength={activeCount}
            toggleActive={toggleActive}
          />
          <Button
            text={'Добавить'}
            icon={PlusCircle}
            onClick={startCreate}
            disabled={!checkAbility(Action.Create, Subject.Parking)}
          />
        </div>
      </div>
      <div className={classes.ListContainer}>
        {list.length > 0 ? (
          list.map((parking) => (
            <ParkingItem
              setEdit={setEdit}
              parking={parking}
              key={parking.id}
              setModalActive={setModalActive}
              startEdit={startEdit}
              checkDeviceActive={checkDeviceActive}
              getPartnerName={getPartnerName}
            />
          ))
        ) : !loading ? (
          <div className={classes.LoaderContainer}>
            <span className={classes.Message}>{message}</span>
          </div>
        ) : (
          <ListLoader />
        )}
      </div>

      <Pagination
        totalCount={!active ? totalCount : activeCount}
        limit={limit}
        offset={offset}
        setOffset={setOffset}
      />

      <Modal modalActive={modalActive} setModalActive={setModalActive}>
        <Form
          setModalActive={setModalActive}
          edit={edit}
          initialValue={initialValue}
          getList={getList}
        />
      </Modal>
    </div>
  )
}
