import React from 'react'
import classes from './PaymentContent.module.sass'
import { CarSign } from '../../../../../common/components/carSign/CarSign'
import signIn from '../../../../../assets/images/icons/SignIn.svg'
import signOut from '../../../../../assets/images/icons/SignOut.svg'
import { format } from 'date-fns'
import {
  BarrierPassInterface,
  PaymentTypes,
  SalesListItemInterface,
} from '../../../../../common/interfaces/interfaces'

type Props = {
  barrierPassIn: BarrierPassInterface | undefined | null
  barrierPassOut: BarrierPassInterface | undefined | null
  sale: SalesListItemInterface | null | undefined
}

export const PaymentContent = ({
  barrierPassIn,
  barrierPassOut,
  sale,
}: Props) => {
  return (
    <div className={classes.Card}>
      <div className={classes.Value}>
        <span className={classes.Name}>Тип оплаты:</span>
        {sale?.paymentType === PaymentTypes.CARD
          ? 'Банковская карта'
          : sale?.paymentType === PaymentTypes.SUBSCRIPTION
          ? 'Абонемент'
          : sale?.paymentType === PaymentTypes.CASH
          ? 'Наличные'
          : 'Нет данных'}
      </div>
      <div className={classes.Value}>
        <span className={classes.Name}>ID парковки:</span>
        {sale?.parkingId}
      </div>
      <div className={classes.ForBorder} />
      {barrierPassIn?.carNumber && (
        <div className={classes.Value}>
          <span className={classes.Name}>
            Номер машины: <CarSign number={barrierPassIn?.carNumber} />{' '}
          </span>
        </div>
      )}
      <div className={classes.ForBorder} />

      <div className={classes.BarrierContainer}>
        <div className={classes.BarrierInfo}>
          <div className={classes.Name}>Шлагбаум:</div>
          <div className={classes.ValueIn}>
            <img src={signIn} />
            {barrierPassIn?.id}
          </div>
        </div>
        <div className={classes.TimeInfo}>
          <div className={classes.Name}>Заезд:</div>
          <div className={classes.DateTime}>
            <span className={classes.DateTitle}></span>{' '}
            <div className={classes.DateTimeWrapper}>
              {barrierPassIn?.dateTime &&
                format(new Date(barrierPassIn?.dateTime), 'dd.MM.yyyy')}
              <div className={classes.Time}>
                {barrierPassIn?.dateTime &&
                  format(new Date(barrierPassIn?.dateTime), 'HH:mm')}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.BarrierContainer}>
        <div className={classes.BarrierInfo}>
          <div className={classes.Name}>Шлагбаум:</div>
          <div className={classes.ValueOut}>
            <img src={signOut} />
            {barrierPassOut?.id}
          </div>
        </div>
        <div className={classes.TimeInfo}>
          <div className={classes.Name}>Выезд:</div>
          <div className={classes.DateTime}>
            <span className={classes.DateTitle}></span>{' '}
            <div className={classes.DateTimeWrapper}>
              {barrierPassOut?.dateTime &&
                format(new Date(barrierPassOut?.dateTime), 'dd.MM.yyyy')}
              <div className={classes.Time}>
                {barrierPassOut?.dateTime &&
                  format(new Date(barrierPassOut?.dateTime), 'HH:mm')}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.ForBorder} />

      {sale?.paymentType === PaymentTypes.SUBSCRIPTION ? (
        <></>
      ) : sale?.cost ? (
        <div className={classes.Payment}>
          <span className={classes.Name}>Сумма оплаты</span>
          <div className={classes.Sum}>
            {sale?.cost} <span className={classes.Rur}>руб.</span>
          </div>
        </div>
      ) : (
        <div className={classes.Payment}>
          <span className={classes.Name}>Не оплачен</span>
          <div className={classes.Sum}></div>
        </div>
      )}
    </div>
  )
}
