import React from "react";
import classes from "./Modal.module.sass";
import X from "../../../assets/images/icons/X.svg";

type Props = {
  children: JSX.Element | JSX.Element[];
  setModalActive: React.Dispatch<React.SetStateAction<boolean>>;
  modalActive: boolean;
  central?: boolean;
  cantClose?: boolean;
};

export const Modal = ({
  children,
  modalActive,
  setModalActive,
  cantClose,
  central,
}: Props) => {
  if (modalActive)
    return (
      <div
        className={!central ? classes.Container : classes.ContainerCentral}
        onClick={() => {
          !cantClose && setModalActive(false);
        }}
      >
        <div className={classes.ContentContainer}>
          {!cantClose && (
            <img
              className={
                !central ? classes.CloseIcon : classes.CloseIconCentral
              }
              onClick={() => {
                setModalActive(false);
              }}
              src={X}
              alt="Закрыть модальное окно"
            />
          )}
          {children}
        </div>
      </div>
    );
  return <></>;
};
