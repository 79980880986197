import React from 'react'
import classes from './CarSign.module.sass'

type Props  = {
    number: string
}

export const CarSign = ({number}: Props) => {
  return (
    <div className={classes.CarSign}>
    <div className={classes.CarSignLeftSide}>
      {number.slice(0, 6)}
    </div>
    <div className={classes.CarSignRightSide}>
      <div className={classes.CarSignCountry}>
        {number.slice(6, 15)}
      </div>
    </div>
  </div>
  )
}
