import classes from './SalesListItem.module.sass'
import {
  PaymentTypes,
  SalesListItemInterface,
} from '../../../../common/interfaces/interfaces'
import { useSalesListItem } from './hooks/useSalesListItem'
import { format } from 'date-fns'
import inIcon from '../../../../assets/images/icons/SignIn.svg'
import outIcon from '../../../../assets/images/icons/SignOut.svg'
import {
  BARRIER_PASS_ROUTE,
  SUBSCRIPTION_ITEM_ROUTE,
} from '../../../../constants'
import { Shorten } from '../../../../common/components/shorten/Shorten'

type Props = {
  el: SalesListItemInterface
  startPaymentClosing: (el: SalesListItemInterface) => void
  startBarrierOpening: (el: SalesListItemInterface) => void
}

export const SalesListItem = ({
  el,
  startPaymentClosing,
  startBarrierOpening,
}: Props) => {
  const { navigate } = useSalesListItem(
    el.barrierPassIn.barrierPassId,
    el.barrierPassOut?.barrierPassId,
  )

  return (
    <div className={classes.Container}>
      <div className={classes.ContentWrapper}>
        <div className={classes.ContentLeftWrapper}>
          <div className={classes.Id}>
            <span className={classes.Name}>id партнера:</span>{' '}
            <Shorten id={el.partnerId} />
          </div>
          <div className={classes.Id}>
            <span className={classes.Name}>id парковки:</span>{' '}
            <Shorten id={el.parkingId} />
          </div>
          {/* <div className={classes.Id}>
          <span className={classes.Name}>id пользователя:</span> {el.userId}
        </div> */}

          <div className={classes.Ways}>
            <div className={classes.WayContainer}>
              <div className={classes.IconAndId}>
                <img src={inIcon} alt="Въезд" />
                <span className={classes.In}>
                  <Shorten id={el.barrierPassIn.barrierPassId} />
                </span>
              </div>

              <div className={classes.DateTime}>
                <span className={classes.DateTitle}>
                  <span className={classes.Name}>Заезд:</span>
                </span>{' '}
                <div className={classes.DateTimeWrapper}>
                  {el.barrierPassIn.dateTime !== null &&
                    format(new Date(el.barrierPassIn.dateTime), 'dd.MM.yyyy')}
                  <div className={classes.Time}>
                    {el.barrierPassIn.dateTime !== null &&
                      format(new Date(el.barrierPassIn.dateTime), 'HH:mm')}
                  </div>
                </div>
              </div>
            </div>

            {/* {el.barrierPassOut && (
            <img src={ArrowCaretRight} alt="От въезда к выезду" />
          )} */}
            {el.barrierPassOut && (
              <div className={classes.WayContainer}>
                <div className={classes.IconAndId}>
                  <img src={outIcon} alt="Выезд" />
                  <span className={classes.Out}>
                  <Shorten id={el.barrierPassOut.barrierPassId} />
                  </span>
                </div>

                <div className={classes.DateTime}>
                  <span className={classes.DateTitle}>
                    <span className={classes.Name}>Выезд:</span>
                  </span>{' '}
                  {el.barrierPassOut.dateTime === null ? (
                    <div>Не выезжал</div>
                  ) : (
                    <div className={classes.DateTimeWrapper}>
                      {format(
                        new Date(el.barrierPassOut.dateTime),
                        'dd.MM.yyyy',
                      )}
                      <div className={classes.Time}>
                        {format(new Date(el.barrierPassOut.dateTime), 'HH:mm')}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          {el.cost > 0 && <div className={classes.Cost}>{el.cost} руб.</div>}
        </div>
        <div className={classes.StatusAndButtonsWrapper}>
          {el.paymentType === PaymentTypes.SUBSCRIPTION && (
            <div
              className={classes.DetailsButton}
              onClick={() =>
                navigate(`${SUBSCRIPTION_ITEM_ROUTE}/${el.subscriptionId}`)
              }
            >
              Абонемент
            </div>
          )}
          {el.cost === 0 ? (
            <div
              className={classes.DetailsButton}
              onClick={() => {
                startPaymentClosing(el)
              }}
            >
              Завершить стоянку
            </div>
          ) : el.barrierPassOut === null ? (
            <div
              className={classes.DetailsButton}
              onClick={() => {
                startBarrierOpening(el)
              }}
            >
              Открыть шлагбаум
            </div>
          ) : (
            <div
              className={classes.DetailsButton}
              onClick={() => {
                navigate(
                  `${BARRIER_PASS_ROUTE}/${el.barrierPassOut?.barrierPassId}`,
                )
              }}
            >
              Детали
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
