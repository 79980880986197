import React from 'react'
import classes from './NotificationList.module.sass'
import { useNotificationList } from './hooks/useNotificationList'
import { NotificationItem } from './components/notificationItem/NotificationItem'

export const NotificationList = () => {
const {list}  = useNotificationList()

  return (
    <div className={classes.Container}>
        {list.map(el=><NotificationItem key={el.id} el={el}/>)}
    </div>
  )
}
