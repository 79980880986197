import React from 'react'
import classes from './Activate.module.sass'
import parking_active from '../../../../assets/images/icons/parking_active.svg'
import parking_inactive from '../../../../assets/images/icons/parking_inactive.svg'
import are_you_sure from '../../../../assets/images/icons/are_you_sure.svg'
import nav from '../../../../assets/images/icons/nav.svg'
import { ParkingInterface } from '../../../../common/interfaces/interfaces'
import { Button } from '../../../../common/components/button/Button'
import { useActivate } from './hooks/useActivate'
import Power from '../../../../assets/images/icons/Power.svg'

type Props = {
  currentParking: ParkingInterface | undefined
  setModalActive: React.Dispatch<React.SetStateAction<boolean>>
  activateParking: () => void
}

export const Activate = ({
  currentParking,
  setModalActive,
  activateParking,
}: Props) => {
  const { inProgress, setInProgress } = useActivate()

  if (!inProgress)
    return (
      <div className={classes.Container}>
        <div className={classes.Title}>Парковка #{currentParking?.id}</div>
        <div className={classes.Address}>
          <img src={nav} alt="Иконка адреса" />
          <span>{currentParking?.address}</span>
        </div>
        <img
          className={classes.Image}
          src={currentParking?.active ? parking_active : parking_inactive}
          alt="Статус парковки"
        ></img>

        <div
          className={
            currentParking?.active
              ? classes.StatusRow
              : classes.StatusRowInActive
          }
        >
          {currentParking?.active ? 'Парковка активна' : 'Парковка неактивна'}
        </div>

        <div className={classes.ButtonsContainer}>
          <Button
            filledGreen={!currentParking?.active}
            filledRed={currentParking?.active}
            icon={Power}
            onClick={() => setInProgress(true)}
            text={currentParking?.active ? 'Деактивировать' : 'Активировать'}
          />
        </div>
      </div>
    )
  return (
    <div className={classes.Container}>
      <img className={classes.ImageSure} src={are_you_sure} alt="Вы уверены?" />
      <div className={classes.Title}>Вы уверены?</div>
      <div className={classes.Comment}>
        {`При ${
          currentParking?.active ? 'выключении' : 'включении'
        } парковки последствия....`}
      </div>
      <div className={classes.ButtonsContainer}>
        <Button
          text="Отменить"
          light
          onClick={() => {
            setModalActive(false)
          }}
        />
        <Button
          text="Подтвердить"
          onClick={() => {
            activateParking()
          }}
        />
      </div>
    </div>
  )
}
