import React from "react";
import classes from "./Shorten.module.sass";
import { useShorten } from "./useShorten";

type Props = {
  id: string;
};

export const Shorten = ({ id }: Props) => {
  const { shortenId } = useShorten();

  return (
    <div className={classes.Container}>
      <div>#{shortenId(id)}</div>
      <div className={classes.PopupAnchor}>
        <div className={classes.PopUp}><div className={classes.PopupInfo}>#{id}</div></div>
      </div>
    </div>
  );
};
