import React from 'react'
import classes from './CounterBookmarkItem.module.sass'

type Props = {
  active: boolean
  title: string
  quantity: number
  toggleActive: () => void
}

export const CounterBookMarkItem = ({
  active,
  title,
  quantity,
  toggleActive,
}: Props) => {
  return (
    <div
      className={active ? classes.ContainerActive : classes.Container}
      onClick={() => !active && toggleActive()}
    >
      {title}
      <div
        className={
          active ? classes.QuantityCircleActive : classes.QuantityCircle
        }
      >
        {quantity}
      </div>
    </div>
  )
}
