import React from 'react'
import classes from './Form.module.sass'
import { Input } from '../../../../common/components/input/Input'
import { Button } from '../../../../common/components/button/Button'
import { useForm } from './hooks/useForm'
import are_you_sure from '../../../../assets/images/icons/are_you_sure.svg'
import {
  Action,
  ParkingInterface,
  Subject,
} from '../../../../common/interfaces/interfaces'
import ParkingAdd from '../../../../assets/images/icons/ParkingAdd.svg'
import ParkingEdit from '../../../../assets/images/icons/ParkingEdit.svg'

import { CheckBox } from '../../../../common/components/checkBox/CheckBox'
import { Select } from '../../../../common/components/select/Select'

type Props = {
  edit?: boolean
  setModalActive: React.Dispatch<React.SetStateAction<boolean>>
  initialValue: ParkingInterface
  getList: () => void
}

export const Form = ({
  edit,
  setModalActive,
  initialValue,
  getList,
}: Props) => {
  const {
    inProgress,
    setInProgress,
    inputHandler,
    data,
    createOrUpdateAction,
    deleteAction,
    getPartnerList,
    getValidationResult,
    disabled,
    setDirty,
    checkAbility,
    currentUser,
  } = useForm(initialValue, getList)

  if (!inProgress)
    return (
      <div className={classes.Container} onClick={(e) => e.stopPropagation()}>
        <div className={classes.InFormWrapper}>
          <img
            className={classes.UserImage}
            src={edit ? ParkingEdit : ParkingAdd}
            alt="Изображение формы"
          />
          <div className={classes.Title}>
            {!edit ? 'Новая парковка' : 'Редактирование'}
          </div>

          <Input
            type={'text'}
            fieldName={'name'}
            value={data.name}
            placeHolder={'Наименование'}
            onChangeHandler={inputHandler}
            itemValidationResult={getValidationResult('name')}
            onBlur={setDirty}
          />

          <Input
            type={'text'}
            fieldName={'address'}
            value={data.address}
            placeHolder={'Адрес'}
            onChangeHandler={inputHandler}
            itemValidationResult={getValidationResult('address')}
            onBlur={setDirty}
          />

          {currentUser.super && (
            <Select
              options={[
                {
                  id: '0',
                  name: 'type',
                  hidden: true,
                  label:
                    getPartnerList().length > 0 ? `Партнер` : 'Нет партнеров',
                  selected: true,
                },
                ...getPartnerList(),
              ]}
              value={data.partnerId}
              onChangeHandler={inputHandler}
              fieldName={'partnerId'}
              type={'select'}
              itemValidationResult={getValidationResult('partnerId')}
              onBlur={setDirty}
            />
          )}

          <div className={classes.CheckBoxes}>
            <CheckBox
              title={'Почасовая оплата'}
              value={data.isHourPrice}
              onChangeHandler={inputHandler}
              fieldName={'isHourPrice'}
              type={'checkbox'}
            />
            <CheckBox
              title={'Активна'}
              value={data.active}
              onChangeHandler={inputHandler}
              fieldName={'active'}
              type={'checkbox'}
            />
          </div>

          <Input
            type={'number'}
            value={data.cost}
            placeHolder={`Цена за ${
              !data.isHourPrice ? 'минуту' : 'час'
            }, руб.`}
            onChangeHandler={inputHandler}
            fieldName={'cost'}
            itemValidationResult={getValidationResult('cost')}
            onBlur={setDirty}
          />

          <Input
            type={'number'}
            value={data.freeMinutesAfterPayment}
            placeHolder={'Бесплатных минут после оплаты'}
            onChangeHandler={inputHandler}
            fieldName={'freeMinutesAfterPayment'}
            itemValidationResult={getValidationResult(
              'freeMinutesAfterPayment',
            )}
            onBlur={setDirty}
          />

          <Input
            type={'number'}
            value={data.capacity}
            placeHolder={'Вместимость'}
            onChangeHandler={inputHandler}
            fieldName={'capacity'}
            itemValidationResult={getValidationResult('capacity')}
            onBlur={setDirty}
          />

          <div className={classes.ButtonsContainer}>
            <Button
              onClick={createOrUpdateAction}
              wide
              text={!edit ? 'Добавить новую парковку' : 'Сохранить изменения'}
              disabled={disabled}
            />
            {edit && (
              <Button
                text="Удалить парковку"
                red
                wide
                onClick={() => setInProgress(true)}
                disabled={!checkAbility(Action.Delete, Subject.Parking)}
              />
            )}
            {!edit && (
              <Button
                wide
                light
                text={'Отмена'}
                onClick={() => setModalActive(false)}
              />
            )}
          </div>
        </div>
      </div>
    )
  return (
    <div className={classes.Container} onClick={(e) => e.stopPropagation()}>
      <div className={classes.InFormWrapper}>
        <img
          className={classes.ImageSure}
          src={are_you_sure}
          alt={'Подтверждение действия'}
        />
        <div className={classes.TitleSure}>Вы уверены? </div>
        <div className={classes.CommentSure}>
          При удалении парковки последствия...
        </div>
        <div className={classes.ButtonsContainer}>
          <Button wide text={'Подтвердить'} onClick={deleteAction} />

          <Button
            wide
            light
            text={'Отменить'}
            onClick={() => setModalActive(false)}
          />
        </div>
      </div>
    </div>
  )
}
