import React from 'react'
import classes from './Button.module.sass'

type Props = {
  text: string
  icon?: string
  wide?: boolean
  light?: boolean
  red?: boolean
  filledGreen?: boolean
  filledRed?: boolean
  onClick?: () => void
  disabled?: boolean
}

export const Button = ({
  text,
  icon,
  wide,
  light,
  red,
  onClick,
  filledGreen,
  filledRed,
  disabled,
}: Props) => {
  return (
    <button
      disabled={disabled}
      onClick={(e) => {
        e.stopPropagation()
        onClick && onClick()
      }}
      className={
        wide && light
          ? classes.ContainerWideLight
          : wide && red
          ? classes.ContainerWideRed
          : light
          ? classes.ContainerLight
          : red
          ? classes.ContainerRed
          : wide
          ? classes.ContainerWide
          : filledGreen
          ? classes.ContainerFilledGreen
          : filledRed
          ? classes.ContainerFilledRed
          : classes.Container
      }
    >
      {icon && <img src={icon} alt="Иконка кнопки" />}
      {text}
    </button>
  )
}
