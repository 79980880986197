import React, { SetStateAction } from 'react'
import are_you_sure from '../../../../../assets/images/icons/are_you_sure.svg'
import classes from './Сonfirmation.module.sass'
import { Button } from '../../../../../common/components/button/Button'

type Props = {
  setModalActive: React.Dispatch<SetStateAction<boolean>>
  deleteGroup: () => void
}

export const Confirmation = ({ setModalActive, deleteGroup }: Props) => {
  return (
    <div className={classes.Container} onClick={(e) => e.stopPropagation()}>
      <div className={classes.InFormWrapper}>
        <img
          className={classes.ImageSure}
          src={are_you_sure}
          alt={'Подтверждение действия'}
        />
        <div className={classes.TitleSure}>Вы уверены? </div>
        <div className={classes.CommentSure}>
          При удалении группы записей последствия...
        </div>
        <div className={classes.ButtonsContainer}>
          <Button wide text={'Подтвердить'} onClick={deleteGroup} />

          <Button
            wide
            light
            text={'Отменить'}
            onClick={() => setModalActive(false)}
          />
        </div>
      </div>
    </div>
  )
}
