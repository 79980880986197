import React from 'react'
import classes from './ParkingItem.module.sass'
import {
  Action,
  ParkingInterface,
  Subject,
} from '../../../../common/interfaces/interfaces'
import { ListButton } from '../../../../common/components/listButton/ListButton'
import nav from '../../../../assets/images/icons/nav.svg'
import { Status } from '../../../../common/components/status/Status'
import { useCurrentUser } from '../../../../common/hooks/useCurrentUser'
import { useAbility } from '../../../../common/hooks/useAbility'
import { Shorten } from '../../../../common/components/shorten/Shorten'
import offline from '../../../../assets/images/icons/offline.svg'
import online from '../../../../assets/images/icons/online.svg'

type Props = {
  parking: ParkingInterface
  setModalActive: React.Dispatch<React.SetStateAction<boolean>>
  setEdit: React.Dispatch<React.SetStateAction<boolean>>
  startEdit: (parking: ParkingInterface) => void
  checkDeviceActive: (id: string) => boolean
  getPartnerName: (partnerId: string) => string
}

export const ParkingItem = ({
  parking,
  startEdit,
  checkDeviceActive,
  getPartnerName,
}: Props) => {
  const { currentUser } = useCurrentUser()

  const { checkAbility } = useAbility()

  return (
    <div className={classes.Container}>
      <div className={classes.InfoWrapper}>
        <div className={classes.Info}>
          <div className={classes.ParkingId}>
            <Shorten id={parking.id} />
          </div>
        </div>
        <div className={classes.Address}>
          <img src={nav} alt={'Иконка адреса'} className={classes.NavIcon} />
          {parking.address}
        </div>
        <img
          src={checkDeviceActive(parking.id) ? online : offline}
          alt="Иконка доступности в сети/не в сети"
        />

        <div className={classes.PerMinuteTimeToExit}>
          <div className={classes.Cost}>
            <span className={classes.Name}>{`За ${
              !parking?.isHourPrice ? 'минуту:' : 'час:'
            } `}</span>
            <div className={classes.CostValue}>
              {parking.cost} <span className={classes.Rur}>руб.</span>
            </div>
          </div>
          <div className={classes.TimeToGoOut}>
            {parking.freeMinutesAfterPayment}
            <span className={classes.Rur}>{`мин.`}</span>{' '}
            <span className={classes.GoOut}>на выезд</span>{' '}
          </div>
        </div>
        <div className={classes.Capacity}>
          {parking.capacity}
          <span className={classes.GoOut}>мест</span>
        </div>
        {currentUser.super && (
          <div className={classes.Number}>
            <span className={classes.GoOut}>Партнер: </span>
            {getPartnerName(parking.partnerId)}
          </div>
        )}
      </div>

      <div className={classes.StatusAndButtonsWrapper}>
        <div className={classes.Active}>
          <Status
            active_props={parking.active}
            title={{ positive: 'Активна', negative: 'Не активна' }}
          />
        </div>
        <div className={classes.VerticalLine} />
        <ListButton
          disabled={!checkAbility(Action.Update, Subject.Parking)}
          onClick={() => {
            startEdit(parking)
          }}
          title={'Редактировать'}
          type={'edit'}
        />
      </div>
    </div>
  )
}
