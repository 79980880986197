import classes from './Payment.module.sass'
import { usePayment } from './hooks/usePayment'
import { PaymentContent } from './components/paymentContent/PaymentContent'
import paymentImage from '../../../assets/images/payment.svg'

export const Payment = () => {
  const { sale, barrierPassIn, barrierPassOut } = usePayment()

  return (
    <div className={classes.Container}>
      <div className={classes.HeaderAndButtonWrapper}>
        <div className={classes.Header}>Оплата #{sale?.id}</div>
      </div>

      <div className={classes.ListContainer}>
        <PaymentContent
          barrierPassIn={barrierPassIn}
          barrierPassOut={barrierPassOut}
          sale={sale}
        />
        <img
          src={paymentImage}
          className={classes.PaymentImage}
          alt="Изображение страницы платежа"
        />
      </div>
    </div>
  )
}
