import React from 'react'
import classes from './SalesItem.module.sass'
import {
  PeriodInterface,
} from '../../../../../common/interfaces/interfaces'
import signOut from '../../../../../assets/images/icons/SignOutBlack.svg'

type Props = {
  item: PeriodInterface
}

export const SalesItem = ({ item }: Props) => {
  return (
    <div className={classes.Container}>
      <div className={classes.ContentWrapper}>
        <div className={classes.LeftSide}>
        <div className={classes.Period}>
          <div className={classes.Bold16}>{item.period}</div>
        </div>
        <div className={classes.Exits}>
          <div className={classes.Wrapper}>
            <img src={signOut} alt="Выездов" />
            Выездов:{' '}
          </div>
          <span className={classes.Bold16}>{item.exits}</span>
        </div>
        <div className={classes.Hours}>
          Часов на парковке:{' '}
          <div className={classes.HoursBlock}>
            <span className={classes.HoursNumber}>{item.hours.toFixed(2)}</span>
            <span className={classes.HoursText}>ч.</span>
          </div>
        </div>
        </div>

        <div className={classes.Sum}>
          Сумма:{' '}
          <div className={classes.Wrapper}>
            <span className={classes.Bold16}>{item.amount}</span>
            <span className={classes.Rur}> руб.</span>
          </div>
        </div>
      </div>
    </div>
  )
}
