import React from 'react'
import classes from './Sales.module.sass'
import { PageHeader } from '../../../common/components/pageHeader/PageHeader'

import { Filter } from '../../../common/components/filter/Filter'
import { useSales } from './hooks/useSales'
import { ParkingItem } from './components/parkingItem/ParkingItem'
import { Loader } from '../../../common/components/loader/Loader'

export const Sales = () => {
  const { list, gte, lte, setFrom, setTo, getReport, loading, message, disabled, dateMessage} =
    useSales()

  return (
    <div className={classes.Container}>
      <div className={classes.HeaderAndButtonWrapper}>
        <PageHeader />
      </div>

      <Filter
        gte={gte}
        lte={lte}
        setFrom={setFrom}
        setTo={setTo}
        getReport={getReport}
        disabled = {disabled}
        dateMessage={dateMessage}
      />
      <div className={classes.ListContainer}>
        {list.data.length > 0 ? (
          list.data.map((el) => <ParkingItem el={el} key={el.parkingId} />)
        ) : !loading ? (
          <div className={classes.LoaderContainer}>
            <span className={classes.Message}>{message}</span>
          </div>
        ) : (
          <div className={classes.LoaderContainer}>
            <Loader />
          </div>
        )}
      </div>
    </div>
  )
}
