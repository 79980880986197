import React from 'react'
import classes from './ListButton.module.sass'
import Nut from '../../../assets/images/icons/Nut.svg'
import NotePencil from '../../../assets/images/icons/NotePencil.svg'
import print from '../../../assets/images/icons/print.svg'

type Props = {
  title: string
  type: 'rights' | 'edit' | 'print'
  onClick?: () => void
  long?: boolean
  disabled?: boolean
}

export const ListButton = ({ title, type, onClick, disabled }: Props) => {
  return (
    <div
      onClick={() => {
        !disabled && onClick && onClick()
      }}
      className={disabled ? classes.ContainerDisabled : classes.Container}
    >
      <img src={type === 'rights' ? Nut : type === 'edit' ? NotePencil : print} alt="Иконка кнопки" />
      <span className={classes.Title}>{title}</span>
    </div>
  )
}
