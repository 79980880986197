import { useCallback, useEffect, useState } from 'react'
import { NotificationTypes, SubscriptionItemInterface } from '../../../common/interfaces/interfaces'
import { getApi } from '../../../api/api'
import { useLogout } from '../../../common/hooks/useLogout'
import { useCurrentUser } from '../../../common/hooks/useCurrentUser'
import { useAbility } from '../../../common/hooks/useAbility'
import { getFileApi } from '../../../api/fileApi'
import { useNotification } from '../../../common/hooks/useNotification'
import { v4 } from 'uuid'

const initialValuePattern: SubscriptionItemInterface = {
  id: '',
  carNumber: '',
  name: '',
  saleDate: new Date(),
  expireDate: new Date(),
  availableMin: '',
  placeReserve: false,
  active: false,
  cost: '',
  partnerId: '',
  parkingId: '',
}

export const useSubscriptions = () => {
  const [list, setList] = useState<SubscriptionItemInterface[]>([])
  const [modalActive, setModalActive] = useState<boolean>(false)
  const [edit, setEdit] = useState<boolean>(false)
  const [initialValue, setInitialValue] = useState<SubscriptionItemInterface>(
    structuredClone(initialValuePattern),
  )
  const [api] = useState(() => getApi())
  const [fileApi] = useState(() => getFileApi())
  const { logOut } = useLogout()
  const { currentUser } = useCurrentUser()

  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')

  const { checkAbility } = useAbility()

  // Pagination ...
  const [activeCount, setActiveCount] = useState<number>(0)
  const [active, setActive] = useState<boolean>(false)
  const [offset, setOffset] = useState(0)
  const [limit] = useState(8)
  const [totalCount, setTotalCount] = useState<number>(0)
  const {addNotificationAction} = useNotification()

  const startEdit = (item: SubscriptionItemInterface) => {
    setEdit(true)
    setInitialValue(item)
    setModalActive(true)
  }

  const startCreate = () => {
    setEdit(false)
    setInitialValue(structuredClone(initialValuePattern))
    setModalActive(true)
  }

  const getList = useCallback(
    async (reUsed?: boolean) => {
      try {
        if (!reUsed) {
          setList([])
          setLoading(true)
          await new Promise<void>((resolve) => setTimeout(() => resolve(), 700))
        }
        const result = await api.getSubscriptionList({
          _start: offset.toString(),
          _end: (offset + limit).toString(),
          _sort: '_id',
          _order: 'desc',
          isActive: active ? active : '',
          partnerId: currentUser.partnerId,
        })
        setModalActive(false)
        setList(result.data)
        setTotalCount(result.total)
        setActiveCount(result.totalActive)
        if (!reUsed) {
          setLoading(false)
          setMessage('Записей нет')
        }
      } catch (error: any) {
        if (error.response.status === 401) logOut().then()
        if (!reUsed) {
          setLoading(false)
          setMessage('Произошла ошибка во время запроса, попробуйте позже')
        }
      }
    },
    [
      api,
      logOut,
      currentUser.partnerId,
      limit,
      offset,
      setTotalCount,
      setActiveCount,
      active,
    ],
  )

  const printSubscription = useCallback(
    async (id: string) => {
      try {
        addNotificationAction({
          id: v4(),
          type: NotificationTypes.SUCCESS,
          text: 'Ожидайте, абонемент откроется в новом окне',
        })
        const result = await fileApi.printSubscription({ id })
        const file = new Blob([result], {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);  
        window.open(fileURL);
        // Download ...
        // const url = URL.createObjectURL(new Blob([result]))
        // const outputFilename = `ticket.pdf`
        // const link = document.createElement('a')
        // link.href = url
        // link.setAttribute('download', outputFilename)
        // document.body.appendChild(link)
        // link.click()
      } catch (error: any) {
        if (error.response.status === 401) logOut().then()
      }
    },
    [api, logOut],
  )

  useEffect(() => {
    getList()
  }, [api, limit, offset, active])

  const toggleActive = () => {
    setActive((prev) => !prev)
  }

  return {
    list,
    modalActive,
    setModalActive,
    startEdit,
    startCreate,
    initialValue,
    edit,
    getList,
    message,
    loading,
    toggleActive,
    totalCount,
    limit,
    offset,
    setOffset,
    active,
    activeCount,
    checkAbility,
    printSubscription,
  }
}
