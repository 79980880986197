import React from 'react'
import classes from './ProgressCircle.module.sass'
import { useProgressCircle } from './hooks/useProgressCircle'

type Props = {
  positive: boolean | undefined
  maxTime: number
}

export const ProgressCircle = ({ maxTime, positive }: Props) => {
  const { currentTime, currentSegment } = useProgressCircle(maxTime)

  return (
    <div className={classes.ProgressCircle}>
      <div
        className={classes.ProgressSegment}
        style={{
          background: `conic-gradient(${
            !positive ? '#46A053' : '#F6583E'
          } ${currentSegment}%,1%,rgba(194, 194, 194, 0.822)`,
        }}
      ></div>
      <div className={classes.ProgressInner}></div>

      <div className={classes.LevelCircleValue}>{currentTime}</div>
    </div>
  )
}
