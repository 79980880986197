import { useCallback, useEffect, useState } from 'react'
import { ListItemInterface } from '../../../../common/interfaces/interfaces'
import { getApi } from '../../../../api/api'
import { useLogout } from '../../../../common/hooks/useLogout'
import { useCurrentUser } from '../../../../common/hooks/useCurrentUser'
import { useAbility } from '../../../../common/hooks/useAbility'

const initialValuePattern: ListItemInterface = {
  id: '',
  carNumber: '',
  comment: '',
  service: false,
  active: false,
  parkingId: '',
  partnerId: '',
}

export const useWhiteList = () => {
  const [list, setList] = useState<ListItemInterface[]>([])
  const [modalActive, setModalActive] = useState<boolean>(false)
  const [modalActive1, setModalActive1] = useState<boolean>(false)
  const [initialValue, setInitialValue] =
    useState<ListItemInterface>(initialValuePattern)
  const [edit, setEdit] = useState<boolean>(false)
  const [groupSelection, setGroupSelection] = useState<
    ListItemInterface[] | []
  >([])
  const [selectedExpanded, setSelectedExpanded] = useState<boolean>(false)
  const [api] = useState(() => getApi())
  const { logOut } = useLogout()
  const { currentUser } = useCurrentUser()
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')

  // Pagination ...
  const [activeCount, setActiveCount] = useState<number>(0)
  const [active, setActive] = useState<boolean>(false)
  const [offset, setOffset] = useState(0)
  const [limit] = useState(8)
  const [totalCount, setTotalCount] = useState<number>(0)

  const {checkAbility} = useAbility()


  const toGroup = (item: ListItemInterface) => {
    groupSelection.map((el) => el.id).includes(item.id)
      ? setGroupSelection((prev) => prev.filter((el) => el.id !== item.id))
      : setGroupSelection((prev) => [...prev, item])
  }

  const resetGroup = () => {
    setGroupSelection([])
  }

  const startEdit = (item: ListItemInterface) => {
    setInitialValue(item)
    setEdit(true)
    setModalActive(true)
  }

  const startCreate = () => {
    setEdit(false)
    setInitialValue(initialValuePattern)
    setModalActive(true)
  }

  const toggleSelectedExpanded = () => {
    setSelectedExpanded((prev) => !prev)
  }

  useEffect(() => {
    groupSelection.length === 0 && setSelectedExpanded(false)
  }, [groupSelection.length])

  const getList = useCallback(
    async (reUsed?: boolean) => {
      try {
        if (!reUsed) {
          setList([])
          setLoading(true)
          await new Promise<void>((resolve) => setTimeout(() => resolve(), 700))
        }
        const result = await api.getWhiteList({
          _start: offset.toString(),
          _end: (offset + limit).toString(),
          _sort: '_id',
          _order: 'desc',
          isActive: active ? active : '',
          partnerId: currentUser.partnerId,
        })
        setModalActive(false)
        setList(result.data)
        setTotalCount(result.total)
        setActiveCount(result.totalActive)
        if (!reUsed) {
          setLoading(false)
          setMessage('Записей нет')
        }
      } catch (error: any) {
        if (error.response.status === 401) logOut().then()
        if (!reUsed) {
          setLoading(false)
          setMessage('Произошла ошибка во время запроса, попробуйте позже')
        }
      }
    },
    [api, logOut, currentUser.partnerId],
  )

  const deleteGroup = useCallback(async () => {
    try {
      await api.deleteWhiteListItem({ id: groupSelection.map((el) => el.id) })
      await getList(true)
      setGroupSelection([])
      setModalActive1(false)
    } catch (error: any) {
      if (error.response.status === 401) logOut().then()
    }
  }, [
    groupSelection,
    api,
    getList,
    logOut,
    limit,
    offset,
    active,
    setActiveCount,
    setTotalCount,
  ])

  useEffect(() => {
    getList()
  }, [api, getList, limit, offset, active])

  const toggleActive = () => {
    setActive((prev) => !prev)
  }

  return {
    list,
    modalActive,
    setModalActive,
    startEdit,
    startCreate,
    initialValue,
    edit,
    groupSelection,
    toGroup,
    resetGroup,
    modalActive1,
    setModalActive1,
    selectedExpanded,
    toggleSelectedExpanded,
    getList,
    deleteGroup,
    loading,
    message,
    totalCount,
    limit,
    offset,
    setOffset,
    active,
    activeCount,
    toggleActive,
    checkAbility
  }
}
