import { useCallback, useEffect, useState } from 'react'
import {
  DeviceItemInterface,
  ParkingInterface,
  SalesReportForDashboardItemInterface,
  Way,
} from '../../../common/interfaces/interfaces'

import { useSelector } from 'react-redux'
import { getApi } from '../../../api/api'
import { useLogout } from '../../../common/hooks/useLogout'
import { useCurrentUser } from '../../../common/hooks/useCurrentUser'
import { useInput } from '../../../common/hooks/useInput'
import { selectPartnerList } from '../../../store/slices/partnerListSlice'
import { selectDeviceStateList } from '../../../store/slices/deviceStateListSlice'

export const useDashBoard = () => {
  const [api] = useState(() => getApi())
  const { logOut } = useLogout()
  const [currentParking, setCurrentParking] = useState<ParkingInterface>()
  const [modalActive, setModalActive] = useState<boolean>(false)
  const [modalActive1, setModalActive1] = useState<boolean>(false)
  const [list, setList] = useState<ParkingInterface[]>([])
  const [deviceList, setDeviceList] = useState<DeviceItemInterface[]>([])
  const partnersList = useSelector(selectPartnerList)
  const [data, setData] = useState<{ partnerList: (string | undefined)[] }>({
    partnerList: [],
  })

  const deviceStateList = useSelector(selectDeviceStateList)

  // Pagination ...
  const [activeCount, setActiveCount] = useState<number>(0)
  const [offset, setOffset] = useState(0)
  const [limit] = useState(6)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [loading, setLoading] = useState(false)
  const { currentUser } = useCurrentUser()
  const [message, setMessage] = useState('')
  const [deviceId, setDeviceId] = useState<string>('')
  const [parkingId, setParkingId] = useState<string>('')
  const [way, setWay] = useState<Way>(Way.out)
  const [filterPlaceHolder, setFilterPlaceHolder] =
    useState<string>('Все партнеры')

  const [revenue, setRevenue] = useState<
    SalesReportForDashboardItemInterface[]
  >([])

  const { inputHandler } = useInput(setData)

  const getList = useCallback(
    async (reUsed?: boolean) => {
      try {
        if (!reUsed) {
          setList([])
          setLoading(true)
          await new Promise<void>((resolve) => setTimeout(() => resolve(), 700))
        }
        const result = await api.getParkingList({
          _start: offset.toString(),
          _end: (offset + limit).toString(),
          _sort: '_id',
          _order: 'desc',
          isActive: '',
          partnerId: data.partnerList.length > 0 ? data.partnerList : [],
        })
        setModalActive(false)
        setList(result.data)
        setTotalCount(result.total)
        setActiveCount(result.totalActive)
        if (!reUsed) {
          setLoading(false)
          setMessage('Записей нет')
        }
      } catch (error: any) {
        if (error.response.status === 401) logOut().then()
        if (!reUsed) {
          setLoading(false)
          setMessage(' ошибка во время запроса, попробуйте позже')
        }
      }
    },
    [
      api,
      logOut,
      limit,
      offset,
      setActiveCount,
      setTotalCount,
      data,
    ],
  )

  useEffect(()=>{
    
  },[data]
  )

  //get devices list and pass devices by parking
  const getDeviceList = useCallback(async () => {
    try {
      const result = await api.getDeviceListByParkingList({
        parkingId: list.map((el) => el.id),
      })
      setModalActive(false)
      setDeviceList(result.data)
    } catch (error: any) {
      if (error.response.status === 401) logOut().then()
    }
  }, [api, logOut, list])

  const getRevenue = useCallback(async () => {
    try {
      const date = new Date()
      const result = await api.getClosedSalesReportForDashboard({
        _gte: new Date(date.getFullYear(), date.getMonth(), 1).toISOString(), // start of month
        _lte: new Date(date.getFullYear(), date.getMonth() + 1).toISOString(), // end of month
        parkingId: list.map((el) => el.id),
      })
      setRevenue(result.data)

      // calculate and set revenue data
    } catch (error: any) {
      if (error.response.status === 401) logOut().then()
    }
  }, [api, limit, offset, list])

  const startBarrierAction = (el: DeviceItemInterface | undefined) => {
    if (el) {
      setDeviceId(el.deviceId)
      setParkingId(el.parkingId)
      setModalActive1(true)
    }
  }

  useEffect(() => {
    getList()
  }, [api, limit, offset, data])
  useEffect(() => {
    if (list.length > 0) {
      getDeviceList()
      getRevenue()
    }
  }, [list])

  const checkDeviceActive = (id: string) => {
    const device = deviceStateList.find((el) => el.deviceId === id)
    if (device) return true
    return false
  }

  const startOpenBarrier = (deviceId: string, parkingId: string, way: Way) => {
    setWay(way)
    setDeviceId(deviceId)
    setParkingId(parkingId)
    setModalActive1(true)
  }

  const getDeviceListByParkingId = (parkingId: string) => {
    return deviceList.filter((el) => el.parkingId === parkingId)
  }

  const getParkingRevenue = (id: string) => {
    return (
      revenue.find((el) => el.parkingId === id) ?? {
        day: 0,
        month: 0,
        parkingId: id,
      }
    )
  }

  const startActivateParking = (currentParking: ParkingInterface) => {
    setCurrentParking(currentParking)
    setModalActive(true)
  }

  const activateParking = useCallback(async () => {
    try {
      if (currentParking) {
        const { active, ...rest } = currentParking
        await api.updateParking({ active: !active, ...rest })
        await getList(true)
      }
    } catch (error: any) {
      if (error.response.status === 401) logOut().then()
    }
  }, [api, currentParking])

  return {
    list,
    currentParking,
    setCurrentParking,
    modalActive,
    setModalActive,
    filterPlaceHolder,
    modalActive1,
    setModalActive1,
    startBarrierAction,
    totalCount,
    limit,
    offset,
    setOffset,
    loading,
    message,
    partnersList,
    currentUser,
    inputHandler,
    data,
    checkDeviceActive,
    startOpenBarrier,
    getDeviceListByParkingId,
    deviceId,
    parkingId,
    way,
    getParkingRevenue,
    startActivateParking,
    activateParking,
  }
}
