import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  DeviceState,
  DeviceStateList,
} from '../../common/interfaces/interfaces'
import { RootState } from '../store'

const initialState: DeviceStateList = {
  data: [],
}

export const deviceStateListSlice = createSlice({
  name: 'device-state-list',
  initialState,
  reducers: {
    setDeviceStateList: (state, action: PayloadAction<DeviceState[]>) => {
      state.data = [...action.payload]
    },
    addDevice: (state, action: PayloadAction<DeviceState>) => {
      state.data = [...state.data, action.payload]
    },
    removeDevice: (state, action: PayloadAction<string>) => {
      const deviceId = state.data.find(
        (el) => el.clientId === action.payload,
      )?.deviceId
      state.data = [
        ...state.data
          .filter((el) => el.parentDeviceId !== deviceId)
          .filter((el) => el.clientId !== action.payload),
      ]
    },
  },
})

export const { setDeviceStateList } = deviceStateListSlice.actions
export const { addDevice } = deviceStateListSlice.actions
export const { removeDevice } = deviceStateListSlice.actions
export const selectDeviceStateList = (state: RootState) =>
  state.deviceStateSList.data

export default deviceStateListSlice.reducer
