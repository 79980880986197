import React from 'react'
import classes from './BarrierPassItem.module.sass'
import { format } from 'date-fns'
import filmState from '../../../../assets/images/icons/FilmSlate.svg'
import { CarSign } from '../../../../common/components/carSign/CarSign'
import { useBarrierPassItem } from './hooks/useBarrierPassItem'
import {
  BarrierPassInterface,
  PaymentTypes,
} from '../../../../common/interfaces/interfaces'
import { Paid } from '../../../../common/components/paid/Paid'
import { PAYMENT_ITEM_ROUTE } from '../../../../constants'
import { InOut } from '../../../../common/components/inOut/InOut'
import { AutoManual } from '../../../../common/components/autoManual/AutoManual'
import { Shorten } from '../../../../common/components/shorten/Shorten'

type Props = {
  log: BarrierPassInterface
  setBarrierLog?: React.Dispatch<
    React.SetStateAction<BarrierPassInterface | undefined>
  >
  setModalActive: React.Dispatch<React.SetStateAction<boolean>>
}

export const BarrierPassItem = ({
  log,
  setBarrierLog,
  setModalActive,
}: Props) => {
  const { navigate } = useBarrierPassItem()

  return (
    <div className={classes.Container}>
      <div className={classes.ContentWrapper}>
        <div className={classes.Row}>
          <div className={classes.Id}>
            <Shorten id={log.id} />
          </div>
        </div>
        <div className={classes.Id}>
          <span className={classes.DeviceTitle}>Устройство:</span>
          <Shorten id={log.deviceId} />
        </div>
        <div className={classes.DateTime}>
          <span className={classes.DateTitle}>Дата:</span>{' '}
          <div className={classes.DateTimeWrapper}>
            {format(new Date(log.dateTime), 'dd.MM.yyyy')}
            <div className={classes.Time}>
              {format(new Date(log.dateTime), 'HH:mm')}
            </div>
          </div>
        </div>
        <div className={classes.RowRight}>
          <AutoManual auto={log.userId ? false : true} />
        </div>
        <div className={classes.UserContainer}>
          {log.userId && (
            <div className={classes.User}>
              Пользователь:{' '}
              <span className={classes.Login}>
                <Shorten id={log.userId} />
              </span>
            </div>
          )}
        </div>
        <div className={classes.Row}>
          <div className={classes.VideoPlaceHolder}>
            {log.videoUrl && (
              <div
                className={classes.VideoWrapper}
                onClick={() => {
                  setBarrierLog && setBarrierLog(log)
                  setModalActive(true)
                }}
              >
                <img src={filmState} alt="Ссылка на видео" />

                <span className={classes.Video}>Видео</span>
              </div>
            )}
          </div>

          <div className={classes.CarSignPlaceHolder}>
            {log.carNumber && <CarSign number={log.carNumber} />}
          </div>
        </div>

        <div className={classes.PaidPlaceHolder}>
          {log.paymentId && (
            <Paid
              type={PaymentTypes.CARD}
              click={() => navigate(`${PAYMENT_ITEM_ROUTE}/${log.paymentId}`)}
            />
          )}
        </div>
      </div>
    </div>
  )
}
