import React, { SetStateAction } from 'react'
import classes from './ActivateBarrier.module.sass'
import { useActivateBarrier } from './hooks/useActivateBarrier'
import areYouShure from '../../../assets/images/aYouShureBarrier.svg'
import { SalesListItemInterface, Way } from '../../interfaces/interfaces'
import { Button } from '../button/Button'
import { ProgressCircle } from '../progressCircle/ProgressCircle'
import { Select } from '../select/Select'
import { Input } from '../input/Input'

type Props = {
  setModalActive: React.Dispatch<SetStateAction<boolean>>
  current?: SalesListItemInterface
  getList?: (reUsed?: boolean) => void
  deviceId?: string
  parkingId?: string
  way: Way
}

export const ActivateBarrier = ({
  current,
  setModalActive,
  getList,
  deviceId,
  parkingId,
  way,
}: Props) => {
  const {
    stage,
    openBarrier,
    data,
    setDirty,
    getDeviceList,
    inputHandler,
    getValidationResult,
    disabled,
  } = useActivateBarrier(
    way,
    setModalActive,
    deviceId,
    parkingId,
    current,
    getList,
  )

  switch (stage) {
    case 'start':
      return (
        <div className={classes.Container}>
          <div className={classes.CircleAndImageContainer}>
            <img
              className={classes.Image}
              src={areYouShure}
              alt="Управление шлагбаумом"
            />
          </div>
          <div className={classes.Comment}>
            {`Вы уверены что хотите открыть шлагбаум?`}
          </div>

          <div className={classes.FieldsContainer}>
            {' '}
            {!deviceId && (
              <Select
                disabled={getDeviceList().length === 0}
                label="Устройство"
                options={[
                  {
                    id: '0',
                    name: 'deviceId',
                    hidden: true,
                    label:
                      getDeviceList().length > 0
                        ? 'Устройство'
                        : 'Нет устройств',
                    selected: true,
                  },
                  ...getDeviceList(),
                ]}
                value={data.deviceId}
                onChangeHandler={inputHandler}
                fieldName={'deviceId'}
                type={'select'}
                itemValidationResult={getValidationResult('deviceId')}
                onBlur={setDirty}
              />
            )}
            <Input
              type={'text'}
              value={data.carNumber}
              placeHolder={'Номер автомобиля'}
              onChangeHandler={inputHandler}
              fieldName={'carNumber'}
              itemValidationResult={getValidationResult('carNumber')}
              onBlur={setDirty}
            />
          </div>

          <div className={classes.ButtonsContainer}>
            <Button
              text="Отменить"
              light
              onClick={() => {
                setModalActive(false)
              }}
            />
            <Button
              disabled={disabled}
              text="Подтвердить"
              onClick={() => {
                openBarrier()
              }}
            />
          </div>
        </div>
      )

    case 'inProgress':
      return (
        <div className={classes.Container}>
          <div className={classes.CircleAndImageContainer}>
            <img
              className={classes.Image}
              src={areYouShure}
              alt="Управление шлагбаумом"
            />
            <div className={classes.ProgressCircleAnchor}>
              <div className={classes.ProgressCircleContainer}>
                <ProgressCircle positive maxTime={5} />
              </div>
            </div>
          </div>
          <div className={classes.Comment}>
            Шлагбаум открывается подождите...
          </div>
          <div className={classes.ButtonsContainer} />
        </div>
      )

    case 'done':
      return (
        <div className={classes.Container}>
          <div className={classes.CircleAndImageContainer}>
            <img
              className={classes.Image}
              src={areYouShure}
              alt="Управление шлагбаумом"
            />
          </div>
          <div className={classes.Comment}>{`Шлагбаум открыт`}</div>
          <div className={classes.ButtonsContainer} />
        </div>
      )
    case 'error':
      return (
        <div className={classes.Container}>
          <img
            className={classes.Image}
            src={areYouShure}
            alt="Управление шлагбаумом"
          />
          <div className={classes.Comment}>{`Не удалось открыть шлагбаум`}</div>
          <div className={classes.ButtonsContainer} />
        </div>
      )

    default:
      return <div className={classes.Container}></div>
  }
}
