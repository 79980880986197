import React from 'react'
import classes from './Users.module.sass'
import { PageHeader } from '../../common/components/pageHeader/PageHeader'
import { useUsers } from './hooks/useUsers'
import { UserItem } from './components/userItem/UserItem'
import { Button } from '../../common/components/button/Button'
import PlusCircle from '../../assets/images/icons/PlusCircle.svg'
import { CounterBookmark } from '../../common/components/counterBookmark/CounterBookmark'
import { Modal } from '../../common/components/modal/Modal'
import { Form } from './components/form/Form'
import { Pagination } from '../../common/components/pagination/Pagination'
import { ListLoader } from '../../common/components/listLoader/ListLoader'
import { Action, Subject } from '../../common/interfaces/interfaces'

export const Users = () => {
  const {
    list,
    modalActive,
    setModalActive,
    edit,
    initialValue,
    startEdit,
    startCreate,
    getList,
    limit,
    offset,
    setOffset,
    loading,
    message,
    toggleActive,
    active,
    activeCount,
    totalCount,
    checkAbility,
    getRoleName
  } = useUsers()

  return (
    <div className={classes.Container}>
      <div className={classes.HeaderAndButtonWrapper}>
        <PageHeader length={totalCount} />
        <div className={classes.BookmarksAndAdd}>
          <CounterBookmark
            active={active}
            allLength={totalCount}
            activeLength={activeCount}
            toggleActive={toggleActive}
          />
          <Button
            onClick={startCreate}
            text={'Добавить'}
            icon={PlusCircle}
            disabled={!checkAbility(Action.Create, Subject.Users)}
          />
        </div>
      </div>

      <div className={classes.UserListContainer}>
        {list.length > 0 ? (
          list.map((user) => (
            <UserItem
              setModalActive={setModalActive}
              user={user}
              key={user.id}
              startEdit={startEdit}
              getRoleName = {getRoleName}
            />
          ))
        ) : !loading ? (
          <div className={classes.LoaderContainer}>
            <span className={classes.Message}>{message}</span>
          </div>
        ) : (
          <ListLoader />
        )}
      </div>

      <Pagination
        totalCount={active ? activeCount : totalCount}
        limit={limit}
        offset={offset}
        setOffset={setOffset}
      />

      <Modal modalActive={modalActive} setModalActive={setModalActive}>
        <Form
          edit={edit}
          setModalActive={setModalActive}
          initialValue={initialValue}
          getList={getList}
        />
      </Modal>
    </div>
  )
}
