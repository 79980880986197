import { useCallback, useEffect, useState } from 'react'
import {
  DeviceItemInterface,
  OptionItemInterface,
  PartnerInterface,
  ValidationResultInterface,
  ValidationRulesInterface,
} from '../../../../../common/interfaces/interfaces'
import { getApi } from '../../../../../api/api'
import { useLogout } from '../../../../../common/hooks/useLogout'
import { useInput } from '../../../../../common/hooks/useInput'
import { deviceValidations, partnerValidations } from '../../../../../constants'
import { useValidation } from '../../../../../common/hooks/useValidation'
import { selectPartnerList } from '../../../../../store/slices/partnerListSlice'
import { useSelector } from 'react-redux'
import { selectParkingList } from '../../../../../store/slices/parkingListSlice'
import { useCurrentUser } from '../../../../../common/hooks/useCurrentUser'

const validations: ValidationRulesInterface[] = deviceValidations

export const useForm = (
  initialValue: DeviceItemInterface,
  getList: (reUsed?: boolean) => void,
) => {
  const [inProgress, setInProgress] = useState<boolean>(false)
  const [data, setData] = useState<DeviceItemInterface>(initialValue)
  const [api] = useState(() => getApi())
  const { logOut } = useLogout()
  const [validationResult, setValidationResult] = useState<
    ValidationResultInterface[] | []
  >([])
  const [disabled, setDisabled] = useState<boolean>(true)
  const partnerList = useSelector(selectPartnerList)
  const parkingList = useSelector(selectParkingList)
  const { currentUser } = useCurrentUser()

  const { validate, checkAndAddValidation, setDirty } = useValidation(
    setValidationResult,
    validationResult,
    validations,
  )

  const { inputHandler } = useInput(setData)

  const getPartnerList = () => {
    let arr: OptionItemInterface[] = []
    for (const item of partnerList) {
      let pattern: OptionItemInterface = {
        id: item.id,
        label: item.name,
        name: item.name,
        value: item.id,
      }
      arr.push(pattern)
    }
    return arr
  }

  const getParkingList = () => {
    let arr: OptionItemInterface[] = []
    for (const item of parkingList.filter(
      (el) => el.partnerId === data.partnerId,
    )) {
      let pattern: OptionItemInterface = {
        id: item.id,
        label: item.name,
        name: item.name,
        value: item.id,
      }
      arr.push(pattern)
    }
    return arr
  }

  useEffect(() => {
    for (var item in data) {
      if (validations.map((el) => el.fieldName).includes(item)) {
        checkAndAddValidation(item)
      }
    }
  }, [])

  useEffect(() => {
    for (var item in data) {
      if (validations.map((el) => el.fieldName).includes(item)) {
        validate(item, data[item])
      }
    }
  }, [data])

  useEffect(() => {
    setDisabled(validationResult.filter((el) => el.notValid).length > 0)
  }, [validationResult])

  const createOrUpdateAction = useCallback(async () => {
    try {
      if (data.id !== '') {
        const { portList, ...rest } = data
        await api.updateDevice({ ...rest })
      } else {
        const { id, portList, ...rest } = data
        await api.createDevice({ ...rest })
      }
      await getList(true)
    } catch (error: any) {
      if (error.response.status === 401) logOut().then()
    }
  }, [api, data, getList, logOut])

  const deleteAction = useCallback(async () => {
    try {
      initialValue.id && (await api.deletePartner({ id: initialValue.id }))
      await getList(true)
    } catch (error: any) {
      if (error.response.status === 401) logOut().then()
    }
  }, [api, getList, initialValue.id, logOut])

  const getValidationResult = (fieldName: string) => {
    return validationResult.find((el) => el.name === fieldName)
  }

  return {
    inProgress,
    setInProgress,
    inputHandler,
    data,
    createOrUpdateAction,
    deleteAction,
    getValidationResult,
    disabled,
    setDirty,
    getParkingList,
    getPartnerList,
    currentUser,
  }
}
