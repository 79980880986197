import { useEffect, useState } from 'react'

export const useStatus = (status?: boolean) => {
  const [active, setActive] = useState<boolean>(false)
  const [expanded, setExpanded] = useState<boolean>(false)

  const statusHandler = () => {
    setActive((prev) => !prev)
    setExpanded(false)
  }

  const expandHandler = () => {
    setExpanded((prev) => !prev)
  }

  useEffect(() => {
    status !==undefined && setActive(status)
  }, [status])

  return { active, statusHandler, expanded, setExpanded, expandHandler }
}
