import React from 'react'
import classes from './Subscription.module.sass'

import passImage from '../../../assets/images/pass.svg'
import { useSubscription } from './hooks/useSubscription'
import { SubscriptionContent } from './components/subscriptionContent/SubscriptionContent'
import { Shorten } from '../../../common/components/shorten/Shorten'

export const Subscription = () => {
  const { subscription } = useSubscription()

  return (
    <div className={classes.Container}>
      <div className={classes.HeaderAndButtonWrapper}>
        <div className={classes.Header}>
          <div>Абонемент</div> <Shorten id={subscription?.id ?? ''} />
        </div>
      </div>
      <div className={classes.ListContainer}>
        <SubscriptionContent subscription={subscription} />
      </div>
      <img
        src={passImage}
        className={classes.PassImage}
        alt="Изображение страницы абонемента"
      />
    </div>
  )
}
