import { SetStateAction } from 'react'
import {
  FormRoleAbility,
  ValidationResultInterface,
  ValidationRulesInterface,
} from './../interfaces/interfaces'

export const useValidation = (
  setValidationResult: React.Dispatch<
    SetStateAction<ValidationResultInterface[]>
  >,
  validationResult: ValidationResultInterface[],
  validations: ValidationRulesInterface[],
) => {
  const checkAndAddValidation = (fieldName: string) => {
    !validationResult.find((el) => el.name === fieldName) &&
      validationResult.push({
        name: fieldName,
        isDirty: false,
        isEmpty: true,
        notValidFormat: false,
        tooShort: false,
        tooLong: false,
        notValid: false,
        isEmptyError: false,
        errorMessage: '',
      })
  }

  const modifyResult = (
    fieldName: string,
    field: string,
    bool: boolean,
    message?: string,
  ) => {
    const data = [...validationResult]
    const currentField = data.find((el) => el.name === fieldName)
    data.filter((el) => el.name !== fieldName)
    if (currentField) {
      if (message) {
        currentField.errorMessage = message
      }
      currentField[field] = bool
    }
    setValidationResult(data)
  }

  const validate = (
    fieldName: string,
    value:
      | string
      | number
      | boolean
      | undefined
      | Date
      | (string | undefined)[]
      | FormRoleAbility[]
  ) => {
    const itemValidations = validations.find((el) => el.fieldName === fieldName)
    if (itemValidations) {
      if (itemValidations.format) {
        if (!itemValidations.format.test(String(value))) {
          modifyResult(
            fieldName,
            'notValidFormat',
            true,
            itemValidations.formatMessage,
          )
        } else {
          modifyResult(fieldName, 'notValidFormat', false, ' ')
        }
      }
      if (itemValidations.minLength) {
        if (
          (typeof value === 'string' &&
            value.length < itemValidations.minLength) ||
          (typeof value === 'number' &&
            value.toString().length < itemValidations.minLength)
        ) {
          modifyResult(
            fieldName,
            'tooShort',
            true,
            itemValidations.toShortMessage,
          )
        } else {
          modifyResult(fieldName, 'tooShort', false)
        }
      }
      if (itemValidations.maxLength) {
        if (
          (typeof value === 'string' &&
            value.length > itemValidations.maxLength) ||
          (typeof value === 'number' &&
            value.toString().length > itemValidations.maxLength)
        ) {
          modifyResult(
            fieldName,
            'tooLong',
            true,
            itemValidations.tooLongMessage,
          )
        } else {
          modifyResult(fieldName, 'tooLong', false)
        }
      }
      if (!itemValidations.canBeEmpty) {
        if (
          value === '' ||
          value === 0 ||
          (Array.isArray(value) && value.length === 0)
        ) {
          modifyResult(fieldName, 'isEmpty', true)
          modifyResult(
            fieldName,
            'isEmptyError',
            true,
            itemValidations.isEmptyMessage,
          )
        } else {
          modifyResult(fieldName, 'isEmptyError', false)
          modifyResult(fieldName, 'isEmpty', false)
        }
      }

      const currentField = validationResult.find((el) => el.name === fieldName)

      const result =
        (!itemValidations.canBeEmpty && currentField?.isEmptyError) ||
        ((itemValidations.maxLength ||
          itemValidations.maxLength !== undefined) &&
          currentField?.tooLong) ||
        ((itemValidations.minLength ||
          itemValidations.minLength !== undefined) &&
          currentField?.tooShort) ||
        ((itemValidations.format || itemValidations.maxLength !== undefined) &&
          currentField?.notValidFormat)

      typeof result === 'boolean' && modifyResult(fieldName, 'notValid', result)
    }
  }

  const setDirty = (fieldName: string) => {
    const currentResult = validationResult.find((el) => el.name === fieldName)
    if (currentResult && !currentResult.isDirty) {
      currentResult.isDirty = true
      setValidationResult((prev) => [
        ...prev.filter((el) => el.name !== currentResult?.name),
        currentResult,
      ])
    }
  }

  return { validate, checkAndAddValidation, setDirty, modifyResult }
}
