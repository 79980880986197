import React from "react";
import classes from "./AutoManual.module.sass";
import colorIcn from "../../../assets/images/icons/ColorIcn.svg";
import colorIcnBlue from "../../../assets/images/icons/ColorIcnBlue.svg";

type Props = {
  auto: boolean | undefined;
};

export const AutoManual = ({ auto }: Props) => {
  return (
    <div className={auto ? classes.Container : classes.ContainerManual}>
      <img src={auto ? colorIcn : colorIcnBlue} alt="Заезд или выезд" />
      <div>{auto ? "Автомат" : "Ручное"}</div>
    </div>
  );
};
