import React from 'react'
import { MenuSubLnk } from '../../../../interfaces'
import classes from './MenuSubItem.module.sass'
import { useMenuSubItem } from './hooks/useMenuSubItem'
import menuArrow from '../../../../../../../assets/images/menuArrow.svg'

type Props = {
  subLink: MenuSubLnk
  toggleMenu: () => void
  menuActive: boolean
}

export const MenuSubItem = ({ subLink, toggleMenu, menuActive }: Props) => {
  const { navigate, location, available } = useMenuSubItem(subLink)
  if (available)
    return (
      <div
        className={
          location.pathname !== subLink.link ? classes.Link : classes.LinkActive
        }
        onClick={() => {
          navigate(subLink.link)
          menuActive && toggleMenu()
        }}
      >
        {subLink.name}{' '}
        {location.pathname === subLink.link && (
          <img
            className={classes.MenuArrow}
            src={menuArrow}
            alt="Активный пункт меню"
          />
        )}
      </div>
    )
  return <></>
}
