import { useParams } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import { BarrierPassInterface, SalesListItemInterface } from '../../../../common/interfaces/interfaces'
import { getApi } from '../../../../api/api'
import { useLogout } from '../../../../common/hooks/useLogout'

export const useBarrierPass = () => {
  const { id } = useParams()
  const [barrierPass, setBarrierPass] = useState<BarrierPassInterface | null>()
  const [sale, setSale] = useState<SalesListItemInterface | null>()
  const [api] = useState(() => getApi())
  const { logOut } = useLogout()

  const getPayment = useCallback(
    async (id: string) => {
      try {
        const result = await api.getSale({ id })
        setSale(result)
      } catch (error: any) {
        if (error.response.status === 401) logOut().then()
      }
    },
    [api, logOut, setSale, id],
  )

  const getBarrierPass = useCallback(async () => {
    try {
      if (id) {
        const result = await api.getBarrierPass({ id })
        setBarrierPass(result)
        if(result && result.paymentId){
          getPayment(result.paymentId)
        }
      }
    } catch (error: any) {
      if (error.response.status === 401) logOut().then()
    }
  }, [api, logOut, id, setBarrierPass])

  useEffect(() => {
    getBarrierPass()
  }, [id])

  return { barrierPass, sale }
}
