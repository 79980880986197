import React from 'react'
import classes from './Select.module.sass'
import {
  OptionItemInterface,
  ValidationResultInterface,
} from '../../interfaces/interfaces'

type Props = {
  label?: string
  options: OptionItemInterface[]
  value?: string | number | readonly string[] | undefined
  onChangeHandler: (value: string, fieldName: string, type: string) => void
  fieldName: string
  type: string
  onBlur?: (fieldName: string) => void
  itemValidationResult?: ValidationResultInterface
  disabled?: boolean
}

export const Select = ({
  label,
  options,
  value,
  onChangeHandler,
  fieldName,
  type,
  onBlur,
  itemValidationResult,
  disabled,
}: Props) => {
  return (
    <div className={classes.SelectContainer}>
      {label && <label className={classes.Label}>{label}</label>}
      <select
        disabled={disabled}
        className={
          itemValidationResult?.isEmptyError
            ? classes.ContainerRequired
            : classes.Container
        }
        defaultValue={value}
        name={fieldName}
        onChange={(e) => onChangeHandler(e.target.value, fieldName, type)}
        onBlur={() => {
          onBlur && onBlur(fieldName)
        }}
      >
        {options.map((el) => (
          <option
            key={el.id}
            hidden={el.hidden}
            selected={el.selected}
            value={el.value}
          >
            {el.label}
          </option>
        ))}
      </select>
      <div className={classes.ValidationErrorField}>
        {itemValidationResult?.isDirty &&
          itemValidationResult.notValid &&
          itemValidationResult?.errorMessage}
      </div>
    </div>
  )
}
